// Store Module
import commonModule from "@/store/common";
import modalModule from "@/store/modal";
import utilityModule from "@/store/utility";
import actions from "@/store/top/top-company/actions";
import getters from "@/store/top/top-company/getters";
import mutations from "@/store/top/top-company/mutations";

const topCompanyModule = {
  namespaced: true,
  modules: {
    common: commonModule,
    modal: modalModule,
    utility: utilityModule,
  },
  state: {
    news: {
      list: [],
      master: [],
    },
    hall: {
      list: [],
      master: [],
    },
  },
  getters,
  mutations,
  actions,
};

export default topCompanyModule;
