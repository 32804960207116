/**
 * Top - mutations
 */
import * as types from "@/store/top/mutationTypes";
import typeToString from "@/lib/typeToString";
import withinDateLimit from "@/lib/withinDateLimit";
import API_CODE from "@/assets/common/ApiCode.js";
import BENEFIT_GROUP_TYPE from "@/assets/common/benefit-group-type";

const periodStatus = (benefit, datePeriod) => {
  const isExpired = (until) => until && !withinDateLimit(until);
  const isSoon = (until, date) => until && !withinDateLimit(until, date);

  let status = 0;
  Object.keys(benefit.publication_until).some((mediaId) => {
    const applicationUntil = benefit.application_until[mediaId];
    const publicationUntil = benefit.publication_until[mediaId];
    const validUntil = benefit.valid_until[mediaId];

    // 期限切れの判定
    const expiredApplication = isExpired(applicationUntil);
    const expiredPublication = isExpired(publicationUntil);
    const expiredValid = isExpired(validUntil);
    if (expiredApplication || expiredPublication || expiredValid) {
      status = 2;
      return true;
    }

    // 期限切れ間近の判定
    const { application_date, publication_date, valid_date } = datePeriod;
    const soonApplication = isSoon(applicationUntil, application_date);
    const soonPublication = isSoon(publicationUntil, publication_date);
    const soonValid = isSoon(validUntil, valid_date);
    if (soonApplication || soonPublication || soonValid) {
      status = 1;
    }
  });
  return status;
};

const mutations = {
  /**
   * 個別ローディング完了
   */
  [types.SET_LOADING_TYPE](state, type) {
    state.loading[type] = false;
  },
  /**
   * 初期データを反映する
   */
  // お知らせ
  [types.SET_NEWS_DATA](state, { data }) {
    state.news.list = data;
  },
  [types.SET_NEWS_MASTER](state, { master }) {
    state.news.master = master;
  },
  // 連携状態
  [types.SET_LINK_DATA](state, data) {
    state.link = { ...data };
  },
  // 媒体関連
  [types.SET_ACCOUNT](state, { data, master, status }) {
    const linkedAccount = Object.keys(data).filter(
      (i) => data[i].link_status > 1
    );
    // 設定数
    state.account.count = linkedAccount.length;
    // 詳細情報
    state.account.list = Object.keys(master).map(function(v) {
      let d = {
        id: master[v].id,
        name: master[v].name,
        short_name: master[v].short_name,
        last_connect_at: null,
        status_id: 0,
        status_title: status[0],
        in_usage: true,
        master: { status },
      };
      if (data[master[v].id]) {
        const media = data[master[v].id];
        d.last_connect_at = media.last_connect_at;
        d.status_id = media.link_status;
        d.status_title =
          media.link_status == 0 || media.in_usage
            ? status[media.link_status]
            : "停止中";
        d.in_usage = media.in_usage;
      }
      return d;
    });
  },
  // 媒体ログ
  [types.SET_ACCOUNT_LOG](state, { log, log_status }) {
    state.account.log = log;
    state.account.log_status = log_status; // status のテキスト表示 link_status と同じ
  },
  // ログイン情報
  [types.SET_ACCOUNT_LOGIN](state, { data }) {
    Object.keys(data).forEach(function(k) {
      state.login[k].id = data[k].login_id;
      state.login[k].password = data[k].password;
    });
  },
  // 特典一覧 & 期限間近の個数
  [types.SET_BENEFIT_DATA](state, { benefit, master }) {
    let extracted = [];
    let cntSoon = 0;
    let cntOver = 0;
    benefit.forEach(function(v) {
      // 期間判定
      if (v.license_status >= 1) {
        if (cntSoon + cntOver < state.benefit.display) {
          let title = v.title;
          if (!title && v.by_media) {
            const media = v.by_media.find((data) => data.title !== null);
            title = media.title;
          } else if (!title) {
            title = " ";
          }
          // 文字数調整
          v.title = title.length > 48 ? title.substr(0, 47) + "…" : title;
          extracted.push(v);
        }
        if (v.license_status == 1) cntSoon++;
        if (v.license_status == 2) cntOver++;
      }
    });
    state.benefit.list = extracted;
    state.benefit.master = master;
    state.benefit.count.expiredNum.soon = cntSoon;
    state.benefit.count.expiredNum.over = cntOver;
  },
  // 特典数
  [types.SET_BENEFIT_COUNT](state, { count, group_type }) {
    state.benefit.count.register = Object.keys(count).length
      ? Object.values(count).reduce((a, c) => a + c)
      : 0;
    state.benefit.count.group = Object.keys(group_type).map(function(v) {
      return { id: v, count: count[v] || 0 };
    });
  },
  // 式場特典の期限切れ・期限切れ間近の数をカウントする
  [types.SET_HALL_BENEFIT_DATA](state, { benefit, master, datePeriod }) {
    const benefits = [...benefit[BENEFIT_GROUP_TYPE.hall], ...benefit[BENEFIT_GROUP_TYPE.wp]];
    const counts = benefits.reduce(
        ({ expired, soon }, benefit) => {
          const status = periodStatus(benefit, datePeriod);
          if (status === 1) {
            return { expired, soon: ++soon };
          }
          if (status === 2) {
            return { soon, expired: ++expired };
          }
          return { expired, soon };
        },
        { expired: 0, soon: 0 }
    );
    state.hallBenefit.master = master;
    state.hallBenefit.count.expiredNum.soon = counts.soon;
    state.hallBenefit.count.expiredNum.over = counts.expired;
  },
  // 画像一覧 & 期限間近の個数
  [types.SET_IMAGE_DATA](state, { image, master }) {
    let extracted = [];
    let cntSoon = 0;
    let cntOver = 0;
    image.forEach(function(v) {
      if (v.license_status >= 1) {
        if (cntSoon + cntOver < state.image.display) {
          extracted.push(v);
        }
        if (v.license_status == 1) cntSoon++;
        if (v.license_status == 2) cntOver++;
      }
    });
    state.image.list = extracted;
    state.image.master = master;
    state.image.count.expiredNum.soon = cntSoon;
    state.image.count.expiredNum.over = cntOver;
  },
  // 画像数
  [types.SET_IMAGE_COUNT](state, { uploaded, max }) {
    state.image.count.register = uploaded;
    state.image.count.max = max;
  },
  // 月ごとのフェア設定数
  [types.SET_FAIR_COUNT_MONTHLY](state, { data }) {
    state.fair.monthly_count = Object.keys(data).map(function(v) {
      let d = {
        days: data[v].days,
        unique_fairs: data[v].unique_fairs,
        month: v.replace(/[0-9]{4}-/, ""),
        year: v.replace(/-[0-9]{1,2}/, ""),
      };
      return d;
    });
  },
  // カレンダー
  [types.SET_CALENDAR](state, { calendar }) {
    state.fair.calendar = calendar;
  },
  // フェア一覧
  [types.SET_FAIR_LIST](state, fairList) {
    state.fair.list = fairList;
  },
  // メディア
  [types.SET_MEDIA](state, { media }) {
    state.media = media;
  },
  // スケジュール
  [types.SET_SCHEDULE](state, { data }) {
    state.fair.schedule = data;
  },
  // プラン一覧
  [types.UPDATE_PLAN_DATA](state, plans) {
    state.plan.list = plans;
  },
  // プラン個数
  [types.UPDATE_PLAN_COUNT](state, count) {
    state.plan.count = count;
  },
  // 連携状態削除
  [types.REMOVE_LINK_CAUTION](state, id) {
    const index = state.link.cautions.findIndex((caution) =>
      caution.logs.map((log) => log.id).some((logId) => logId == id)
    );
    if (index >= 0) state.link.cautions.splice(index, 1);
  },
  // ログ削除
  [types.REMOVE_LOG](state, { id, media_id }) {
    const index = state.account.log[media_id].notices.findIndex(
      (v) => v.id == id
    );
    if (index >= 0) state.account.log[media_id].notices.splice(index, 1);
  },
  // ログモーダル表示切替
  [types.SHOW_LOG_MODAL]: (state, isShow) => {
    state.isLogModal = isShow;
  },
  // 各 unit の表示権限
  [types.SET_IS_AUTHORIZED_UNIT](state, payload) {
    const { name, value } = payload;
    if (typeToString(value) == "Object") {
      Object.keys(value).forEach((k) => {
        state.isAuthorizedUnit[name][k] = value[k];
      });
    }
  },
};

export default mutations;
