import { createRouter, createWebHistory } from "vue-router";
import TopView from "@/views/TopView.vue";
import ErrorView from "@/views/ErrorView.vue";

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      name: "top",
      path: "/",
      component: TopView,
    },
    {
      name: "error",
      path: "/:pathMatch(.*)*",
      component: ErrorView,
    },
  ],
});
