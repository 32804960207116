const mutations = {
  SET_HALL_INIT_SETTING(state, hallInitSetting) {
    state.hallInitSetting = hallInitSetting;
  },
  SET_HALL_SETTING_MASTER(state, hallSettingMaster) {
    state.hallSettingMaster = hallSettingMaster;
  },
  SET_MEDIA_ACCOUNT(state, mediaAccount) {
    state.mediaAccount = mediaAccount;
  },
};

export default mutations;
