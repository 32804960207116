<template>
  <div class="dayRow" :class="{ sum: type == 'unique_fairs' }">
    <div v-for="ym in Object.keys(calendar)" :key="ym" class="dayCellWrap">
      <day-cell
        v-for="(v, d) in calendar[ym]"
        :key="d"
        :day="v.day"
        :weekday="v.weekday"
        :count="count(v)"
        :is-holiday="v.is_holiday"
      />
    </div>
  </div>
</template>

<script>
import DayCell from "@/components/atoms/top/DayCell.vue";

export default {
  components: {
    DayCell,
  },
  props: {
    type: [String, Number],
    calendar: Object,
    schedule: Object,
  },
  methods: {
    count: function(data) {
      let count = 0;
      if (
        this.schedule[data.year_month] &&
        this.schedule[data.year_month][data.day]
      ) {
        count = this.schedule[data.year_month][data.day][this.type];
      }
      return count;
    },
  },
};
</script>

<style scoped lang="scss">
.dayRow,
.dayCellWrap {
  align-items: center;
  display: inline-flex;
  height: 100%;
}
.dayCellWrap {
  border-left: $SCHEDULE_MONTH_BORDER;
}
.dayRow {
  background: rgba(50, 50, 50, 0.05);
  height: $GRID * 2;
  margin-top: 2px;
  &.sum {
    background: $BG_COLOR_BOX;
    box-shadow: $DEPTH_LV_0;
    height: $GRID * 4;
    margin-top: 0;
    ::v-deep .d {
      font-size: 16px;
    }
  }
  &:not(.sum) {
    ::v-deep .isSaturday {
      background: rgba($COLOR_SATURDAY, 0.05);
    }
    ::v-deep .isSunday,
    ::v-deep .isHoliday {
      background: rgba($COLOR_SUNDAY, 0.05);
    }
  }
}
</style>
