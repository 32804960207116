<template>
  <div class="stepUnit" :class="{ isDisabled, isOptional }">
    <div class="stepUnitTitle" :class="{ isChecked }">
      <i class="material-icons" v-if="!isOptional">check</i>
      <p class="stepUnitName" :class="{ isOptional }">
        <span v-if="!isOptional && num" class="stepUnitNameNo fontNum-b">
          {{ num }}.
        </span>
        <span>{{ name }}</span>
      </p>
    </div>
    <div class="stepUnitNote">
      <p class="stepUnitNoteMain">{{ text }}</p>
      <p class="stepUnitNoteSub" v-if="subText" v-html="subText"></p>
    </div>
    <action-button
      class="stepUnitButton"
      :is-disabled="isDisabled"
      :text="buttonText"
      @clickHandler="move"
    />
  </div>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton.vue";

export default {
  components: {
    ActionButton,
  },
  props: {
    buttonText: {
      type: String,
      default: "設定する",
    },
    name: String,
    num: Number,
    isChecked: Boolean,
    isDisabled: Boolean,
    isOptional: Boolean,
    path: String,
    subText: String,
    text: String,
  },
  methods: {
    move: function() {
      if (this.path) window.location.href = this.path;
    },
  },
};
</script>

<style scoped lang="scss">
.stepUnit {
  align-items: center;
  background: $BG_COLOR_BOX;
  border-radius: $BOX_RADIUS;
  box-shadow: $DEPTH_LV_0;
  color: $COLOR_KEY;
  display: flex;
  font-size: $GRID;
  padding: $GRID;
  &.isDisabled {
    opacity: 0.5;
    &:hover {
      cursor: not-allowed;
    }
  }
  &.isOptional {
    background: #eee;
    font-size: 14px;
  }
  &Button {
    margin: auto 0 auto auto;
  }
  &Name {
    display: flex;
    font-weight: bold;
    &No {
      font-size: 20px;
      margin-top: -2px;
    }
  }
  &Note {
    color: $COLOR_SUB_TEXT;
    font-size: 14px;
    &Sub {
      font-size: 12px;
      margin-top: $GRID * 0.5;
    }
  }
  &Title {
    align-items: center;
    display: flex;
    min-width: $GRID * 14;
    &.isChecked {
      color: $COLOR_SUBMIT;
      .material-icons {
        color: $COLOR_SUBMIT;
      }
    }
    .material-icons {
      color: $COLOR_DISABLE_TEXT;
    }
    i,
    span {
      padding-right: $GRID * 0.5;
    }
  }
  & + & {
    margin-top: $GRID;
  }
}
</style>
