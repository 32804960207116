import { createApp, configureCompat } from "vue";
import App from "@/pages/top/App.vue";
import router from "@/pages/top/router.js";
import store from "@/store/top";

import { setGuard } from "@/lib/router/index.js";
setGuard(router);

import { sync } from "vuex-router-sync";
sync(store, router);

configureCompat({
  MODE: 3,
});

createApp(App).use(router).use(store).mount("#app");
