<template>
  <div class="monthlyBlock">
    <div class="ym">
      <p class="y fontNum">{{ data.year }}</p>
      <p class="m fontNum">{{ data.month }}</p>
    </div>
    <div class="monthlyBlockInr">
      <div class="countUnit">
        <text-count text="フェア設定日数" :count="data.days" />
        <p class="countUnit_day">
          <span class="fontNum">{{ days }}</span>日
        </p>
      </div>
      <div class="countUnit">
        <text-count text="フェア種類" :count="data.unique_fairs" />
        <p class="countUnit_type">種類</p>
      </div>
    </div>
  </div>
</template>

<script>
import TextCount from "@/components/atoms/top/TextCount.vue";

export default {
  components: {
    TextCount,
  },
  props: {
    data: Object,
  },
  computed: {
    days: function() {
      return new Date(this.data.year, this.data.month, 0).getDate();
    },
  },
};
</script>

<style scoped lang="scss">
.monthlyBlock {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: $GRID $GRID * 3.5 $GRID $GRID;
  width: calc(100% / 3);
  .ym {
    background: rgba(255, 255, 255, 0.4);
    border-radius: $BOX_RADIUS * 0.5;
    box-shadow: $DEPTH_LV_0;
    color: $COLOR_KEY;
    font-weight: bold;
    padding: $GRID 0;
    margin-right: $GRID;
    text-align: center;
    width: $GRID * 5;
    .y {
      font-size: 11px;
      letter-spacing: 0.06em;
    }
    .m {
      font-size: 32px;
      line-height: 1;
      margin-top: 3px;
    }
  }
  &Inr {
    width: $GRID * 8;
  }
  .countUnit {
    position: relative;
    &_day,
    &_type {
      font-size: 11px;
      line-height: 1;
      position: absolute;
    }
    &_day {
      bottom: 0;
      right: -3em;
      &::before {
        bottom: 1px;
        content: "/";
        display: inline;
        font-size: 14px;
        left: -7px;
        position: absolute;
      }
      .fontNum {
        color: $COLOR_KEY;
        font-size: 14px;
      }
    }
    &_type {
      bottom: 3px;
      right: -2.5em;
    }
    &:first-child {
      margin-bottom: $GRID * 0.5;
    }
  }
  & + & {
    border-left: 1px solid $BG_COLOR_FLOOR;
  }
}
</style>
