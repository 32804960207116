<template>
  <div class="mediaDetail" :class="classObject">
    <div class="mediaDetailInr" @click="showLog">
      <p class="mediaDetailName">{{ data.name }}</p>
      <state-icon
        :id="stateIconId"
        :title="stateIconTitle"
        :in-usage="data.in_usage"
      />
      <p class="mediaDetailLast fontNum">{{ elapsedTime }}</p>
      <p class="mediaDetailConfirmBtn" v-if="isConfirm">{{ confirmText }}</p>
    </div>
    <div v-if="isShowLogin" class="mediaDetailLogin">
      <a
        v-if="login.url"
        class="mediaDetailLoginBtn"
        target="_blank"
        :href="login.url"
      >
        <span class="mediaDetailLoginBtn_txt">管理画面</span>
      </a>
      <form v-else method="post" target="_blank" :action="login.action">
        <input type="text" :name="login.param.id" :value="login.id" hidden />
        <input
          type="password"
          :name="login.param.password"
          :value="login.password"
          hidden
        />
        <button
          type="submit"
          class="mediaDetailLoginBtn"
          :name="login.param.submit"
          :value="login.submit"
        >
          <span class="mediaDetailLoginBtn_txt">管理画面</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import API_CODE from "@/assets/common/ApiCode";
import { AccountStatusType } from "@/assets/common/AccountStatusType";

import makeElapsedTimeText from "@/lib/makeElapsedTimeText";
import StateIcon from "@/components/atoms/common/StateIcon.vue";

export default {
  components: {
    StateIcon,
  },
  props: {
    data: Object,
    login: Object,
    logs: Array,
    isShowLogin: Boolean,
  },
  computed: {
    hasLogs: function() {
      return this.logs.length > 0;
    },
    // 即時反映が必要なJOBのエラーのみか
    hasImmediateJobErrorsOnly() {
      return (
          this.data.in_usage &&
          this.data.status_id >= API_CODE.linkStatus.error &&
          !this.hasLogs
      );
    },
    isError: function() {
      return (
        this.data.in_usage &&
        this.data.status_id >= API_CODE.linkStatus.error &&
        this.hasLogs
      );
    },
    isNotice: function() {
      return this.data.status_id === API_CODE.linkStatus.notice;
    },
    isWaiting: function() {
      return this.data.status_id === API_CODE.linkStatus.waiting;
    },
    isConfirm: function() {
      return this.isError || this.isWaiting || this.isNotice;
    },
    elapsedTime: function() {
      const text = this.data.last_connect_at
        ? makeElapsedTimeText(this.data.last_connect_at)
        : "";
      return text;
    },
    classObject: function() {
      return {
        isEmpty: this.data.status_id === 0,
        isError: this.isError,
        isNotice: this.isNotice,
        isWaiting: this.isWaiting,
        isConfirm: this.isConfirm,
      };
    },
    confirmText: function() {
      if (this.isWaiting || this.isNotice) return "内容確認";
      if (this.isError) return "エラー内容確認";
    },
    stateIconId() {
      return this.hasImmediateJobErrorsOnly
        ? AccountStatusType.PUBLISHING
        : this.data.status_id;
    },
    stateIconTitle() {
      return this.hasImmediateJobErrorsOnly
        ? this.data.master.status[AccountStatusType.PUBLISHING]
        : this.data.status_title;
    },
  },
  methods: {
    showLog: function() {
      let data = {
        id: this.data.id,
        name: this.data.name,
      };
      if (this.isConfirm) this.$emit("showLog", data);
    },
    onClick: function() {
      alert(this.data.name);
    },
  },
};
</script>

<style scoped lang="scss">
.mediaDetail {
  border-left: 1px solid #ececec;
  text-align: center;
  width: $GRID * 8 + $SUB_GRID;
  &Inr {
    height: $GRID * 7;
    position: relative;
    .isConfirm & {
      cursor: pointer;
      &:hover {
        .mediaDetailConfirmBtn {
          &::before {
            width: 100%;
          }
        }
      }
    }
    .isConfirm & {
      &:hover {
        ::v-deep .material-icons {
          animation: hoverConfirm 0.8s ease-in-out;
          transform-origin: bottom center;
        }
      }
    }
  }
  &Name {
    font-size: 11px;
    font-weight: bold;
    line-height: 1;
    padding: $SUB_GRID * 2 0 $SUB_GRID * 2.5;
    .isConfirm & {
      padding-bottom: $SUB_GRID * 2;
    }
  }
  &Last {
    bottom: $GRID * 0.5;
    font-size: 11px;
    color: #727171;
    left: 0;
    line-height: 1;
    position: absolute;
    text-align: center;
    width: 100%;
    .isConfirm & {
      bottom: $GRID * 2;
    }
  }
  &Login {
    border-top: 2px solid #ececec;
    padding: $GRID * 0.5;
    .isEmpty & {
      border-color: #e3e3e3;
    }
    .isError & {
      border-color: lighten($COLOR_ALERT, 31%);
    }
    .isNotice & {
      border-color: lighten($COLOR_WARNING, 31%);
    }
    &Btn {
      @include btn($COLOR_SUB_TEXT);
      background: $COLOR_SUB_LIGHT_TEXT;
      border: none;
      border-radius: $GRID;
      box-shadow: $DEPTH_LV_0;
      color: #fff;
      display: block;
      font-size: 11px;
      line-height: $GRID * 1.5;
      width: 100%;
      &_txt {
        position: relative;
      }
    }
  }
  &.isEmpty {
    background: #eee;
    & + &,
    & + .isError,
    & + .isNotice {
      border-color: #e3e3e3;
    }
  }
  &.isError,
  &.isNotice {
    .mediaSet + & {
      border-color: #e3e3e3;
    }
  }
  &.isError {
    background: $BG_COLOR_ALERT;
    & + & {
      border-color: lighten($COLOR_ALERT, 31%);
    }
  }
  &.isNotice {
    background: $BG_COLOR_WARNING;
    & + & {
      border-color: lighten($COLOR_WARNING, 31%);
    }
  }
  &ConfirmBtn {
    @include btn(rgba($COLOR_SUB_LIGHT_TEXT, 0.1));
    background: #fff;
    border: 1px solid $COLOR_SUB_LIGHT_TEXT;
    border-radius: 18px;
    bottom: $GRID * 0.5;
    box-shadow: $DEPTH_LV_0;
    font-size: 10px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    width: $GRID * 6.5;
    .isError & {
      border-color: $COLOR_ALERT;
      color: $COLOR_ALERT;
      &::before {
        background: rgba($COLOR_ALERT, 0.1);
      }
    }
    .isNotice & {
      background: $COLOR_WARNING;
      border-color: $COLOR_WARNING;
      &::before {
        background: rgba(#fff, 0.1);
      }
    }
  }
}

@keyframes hoverConfirm {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
