<template>
  <ul class="linkCautionLogs" v-if="showLogs">
    <li class="linkCautionLogs_item" v-for="(log, index) in logs" :key="index">
      <div class="linkCautionLog">
        <p class="linkCautionLog_id">#{{ log.id }}</p>
        <p class="linkCautionLog_title">{{ logTitle(index) }}</p>
        <link-button
          class="button"
          v-if="hasLogBtn(index) && logPath(index) && log.can_edit === true"
          :path="logPath(index)"
          :text="logBtnText"
          :is-small="true"
        />
        <action-button
          class="button"
          v-if="log.can_relink === true"
          text="再連携する"
          :is-small="true"
          @clickHandler="retry(log.job_id)"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import LinkButton from "@/components/atoms/common/LinkButton.vue";
import { getLogPath } from "@/store/common/helper";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ActionButton,
    LinkButton,
  },
  props: {
    logs: Array,
    type: String,
  },
  computed: {
    ...mapGetters({
      isLogAuthorized: "common/isLogAuthorized",
    }),
  },
  created() {
    this.setByType();
    this.showLogs = this.logs.length > 0;
  },
  methods: {
    ...mapActions({ retry: "retry" }),
    hasLogBtn(i) {
      return this.isLogAuthorized(this.logs[i].queueable_type);
    },
    logTitle(i) {
      const log = this.logs[i];
      let title = `#${log.queueable_id}`;
      if (log.queueable_title) title += ` ${log.queueable_title}`;

      if (!log.header) return title;
      if (log.queueable_type === "PlanOrder") return log.header;
      return `${log.header} ${title}`;
    },
    logPath(i) {
      const log = this.logs[i];
      switch (log.queueable_type) {
        case "Fair":
          const type = this.type === "daily" ? "FairDaily" : log.queueable_type;
          return `${getLogPath(type)}${log.queueable_id}`;

        case "HallBenefit":
          const query = `?hall_benefit_id=${log.queueable_id}&media_id=${log.media_id}`;
          return `${getLogPath(log.queueable_type)}${query}`;

        case "FairOrder":
          return `${getLogPath(log.queueable_type)}${log.media_id}`;

        case "Benefit":
        case "Plan":
        case "Image":
        case "HallInfo":
          return `${getLogPath(log.queueable_type)}${log.queueable_id}`;

        default:
          return getLogPath(log.queueable_type);
      }
    },
    setByType() {
      this.logBtnText = "編集する";

      switch (this.type) {
        case "contact":
        case "maintenance":
        case "daily":
          this.logBtnText = "確認する";
          return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.linkCautionLogs {
  margin-top: $GRID;
  &_item {
    list-style: none;
    margin-top: $SUB_GRID;
    &:first-child {
      margin-top: 0;
    }
  }
}

.linkCautionLog {
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 8px;
  color: $COLOR_SUB_TEXT;
  display: flex;
  font-size: $FONT_SIZE_SS;
  padding: $SUB_GRID $SUB_GRID * 2;

  &_id {
    margin-right: $SUB_GRID * 2;
  }

  &_title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .button {
    flex-shrink: 0;
    height: 18px;
    padding: 2px 0 1px;
    width: $GRID * 5;

    & + .button {
      margin-left: $SUB_GRID;
    }
  }
}
</style>
