<template>
  <div class="fairUnit">
    <block-title icon="date_range" title="フェア設定" :isSingle="false">
      <template #btn>
        <div class="group">
          <p>フェアマスタ：</p>
          <link-button text="新規登録する" href="/fair-master-edit/" />
          <link-button text="一覧" href="/fair-master-list/" />
        </div>
        <div class="group">
          <link-button text="スケジュール設定" href="/fair-schedule/" />
        </div>
      </template>
    </block-title>
    <div class="topBlock">
      <div class="monthlyList">
        <monthly-count
          v-for="v in fair.monthly_count"
          :key="v.month"
          :data="v"
        />
      </div>
      <div class="loadSchedule" v-if="loading.fair">
        <div class="loadScheduleInr">
          <inoperable-cover
            text="スケジュールを読み込んでいます…"
            :is-small="true"
          />
        </div>
      </div>
      <p class="emptySchedule" v-else-if="isEmptySchedule">
        <i class="material-icons">warning</i>スケジュールが設定されていません
      </p>
      <div
        class="calendarWrap"
        id="calendarWrap"
        v-else
        ref="calendar"
        :style="calendarHeight"
        v-on:scroll.passive="onScroll"
      >
        <div class="calendarInr">
          <calendar-header
            class="calendarHeader"
            :calendar="fair.calendar"
            @scrollToMonthHandler="scrollToMonth"
          />
          <div
            class="calendarTitWrap"
            :class="{ isOpen: isMediaOpen }"
            :style="moveLeft"
          >
            <p class="calendarTit setting">フェア種類</p>
            <div v-if="mediaNum > 0" class="calendarSubTitWrap">
              <button
                type="button"
                class="calendarSubTit"
                @click="chageIsMediaOpen()"
              >
                <span v-if="isMediaOpen">
                  媒体ごとのフェア種類を閉じる
                  <i class="material-icons">keyboard_arrow_up</i>
                </span>
                <span v-else>
                  媒体ごとのフェア種類を確認する
                  <i class="material-icons">keyboard_arrow_down</i>
                </span>
              </button>
              <div class="calendarMediaHeader" :class="{ isOpen: isMediaOpen }">
                <p class="calendarTit" v-for="v in media" :key="v.id">
                  <span>{{ v.short_name }}</span>
                </p>
              </div>
            </div>
          </div>
          <fair-daily-count
            :class="{ isSetMedia: mediaNum > 0 }"
            type="unique_fairs"
            :calendar="fair.calendar"
            :schedule="fair.schedule"
          />
          <div class="calendarMedia" :class="{ isOpen: isMediaOpen }">
            <fair-daily-count
              v-for="v in media"
              :key="v.id"
              :type="v.id"
              :calendar="fair.calendar"
              :schedule="fair.schedule"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
const scrollVerticalOptions = {
  container: "#calendarWrap",
  offset: -176,
  x: true,
  y: false,
};

import BlockTitle from "@/components/molecules/top/BlockTitle.vue";
import MonthlyCount from "@/components/molecules/top/FairMonthlyCount.vue";
import FairDailyCount from "@/components/molecules/top/FairDailyCount.vue";
import CalendarHeader from "@/components/molecules/top/CalendarHeader.vue";
import LinkButton from "@/components/atoms/common/LinkButton.vue";
import InoperableCover from "@/components/atoms/common/InoperableCover.vue";
// Store
import { mapState } from "vuex";

export default {
  components: {
    BlockTitle,
    MonthlyCount,
    FairDailyCount,
    CalendarHeader,
    LinkButton,
    InoperableCover,
  },
  props: {},
  data: function () {
    return {
      el: this.$refs.calendar,
      isMediaOpen: false,
      moveLeft: {
        transform: "translateX(0px)",
      },
    };
  },
  mounted() {
    this.el = this.$refs.calendar;
  },
  updated() {
    if (!this.el) this.el = this.$refs.calendar;
  },
  computed: {
    ...mapState(["loading", "fair", "media"]),
    mediaNum: function () {
      return Object.keys(this.media).length;
    },
    isEmptySchedule: function () {
      return Object.keys(this.fair.schedule).length == 0;
    },
    calendarHeight: function () {
      const headH = 32 + 48;
      const numH = 64;
      const mediaH = 32;
      const scrollbarH = 18;
      let marginH = 10 * 2; // フェア種類の上下の余白
      let h = headH + numH + scrollbarH;
      if (this.mediaNum) marginH += 5 * 2 + 16 * 1.5; // アコーディオンボタンの高さ＋上下の余白
      if (this.isMediaOpen && this.mediaNum) {
        marginH += 2 * this.mediaNum + 10; // 媒体個別ごとの余白＋最下部の余白
        h += mediaH * this.mediaNum;
      }
      h += marginH;
      return { height: h + "px" };
    },
  },
  methods: {
    onScroll() {
      this.moveLeft.transform = `translateX(${this.el.scrollLeft}px)`;
    },
    chageIsMediaOpen: function () {
      this.isMediaOpen = !this.isMediaOpen;
    },
    scrollToMonth: function (index) {
      VueScrollTo.scrollTo(
        "#calendar_month-" + index,
        "calendarWrap",
        scrollVerticalOptions
      );
    },
  },
};
</script>

<style scoped lang="scss">
.monthlyList {
  @include gradientMain();
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
}
.calendar {
  &Wrap {
    background: $BG_COLOR_SUB;
    border-top: 1px solid $BG_COLOR_SUB;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    transition: height 0.3s;
    width: 100%;
  }
  &Inr {
    left: 0;
    padding: 0 0 $GRID $GRID * 11;
    position: absolute;
    top: 0;
  }
  &Header {
    border-bottom: none;
    margin-bottom: $SUB_GRID * 2;
    padding-left: 0;
    ::v-deep .c_h {
      display: none;
    }
  }
  &Tit {
    &Wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: $GRID * 11;
      margin-top: $SCHEDULE_HEADER_HEIGHT;
      z-index: 10;
      background: $BG_COLOR_SUB;
      &::before {
        background: $BG_COLOR_BOX;
        bottom: 100%;
        box-shadow: 0px -3px 4px 1px rgba(0, 0, 0, 0.1);
        content: "";
        display: block;
        height: $SCHEDULE_HEADER_HEIGHT;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }

    & {
      align-items: center;
      background: rgba(50, 50, 50, 0.05);
      border-radius: $BOX_RADIUS * 0.5 0 0 $BOX_RADIUS * 0.5;
      box-shadow: 4px 0px 3px -3px rgba(0, 0, 0, 0.08);
      color: #726e6e;
      display: flex;
      font-size: 10px;
      font-weight: bold;
      height: $GRID * 2;
      margin: 2px 0 0 auto;
      padding-left: $GRID * 1.5;
      width: $GRID * 10 + $SUB_GRID;
      &.setting {
        background: $BG_COLOR_BOX;
        box-shadow: $DEPTH_LV_0;
        font-size: 14px;
        height: $GRID * 4;
        margin: $SUB_GRID * 2 0 0 auto;
        padding-left: $GRID;
      }
    }
  }
  &SubTit {
    &Wrap {
      padding-top: $GRID * 1.5 + $SUB_GRID * 3.5;
      position: relative;
    }
    @include btn();
    background: $BG_COLOR_FLOOR;
    border: none;
    border-radius: $GRID;
    display: block;
    color: #726e6e;
    font-size: 10px;
    left: $SUB_GRID * 2;
    line-height: $GRID * 1.5;
    position: absolute;
    padding-left: $SUB_GRID * 2.5;
    text-align: left;
    top: $SUB_GRID * 2;
    width: $GRID * 13.5;
    .material-icons {
      bottom: 0;
      font-size: 20px;
      line-height: $GRID * 1.5;
      position: absolute;
      right: $SUB_GRID * 0.5;
    }
  }
  &MediaHeader {
    opacity: 0;
    transition: opacity 0.3s;
    &.isOpen {
      opacity: 1;
    }
  }
  &Media {
    opacity: 0;
    transition: opacity 0.3s;
    &.isOpen {
      opacity: 1;
      margin-top: 42px;
    }
  }
}
.isSetMedia {
  margin-bottom: $GRID * 1.5 + $SUB_GRID * 4 -2px;
}
.loadSchedule {
  margin-top: $GRID;
  padding: 0 $GRID $GRID;
  width: 100%;
  &Inr {
    height: $GRID * 6;
    position: relative;
  }
}
.emptySchedule {
  color: $COLOR_KEY;
  font-size: 14px;
  padding: $GRID * 2 0;
  text-align: center;
  .material-icons {
    color: $COLOR_WARNING;
    font-size: 20px;
    margin-right: 4px;
  }
}
</style>
