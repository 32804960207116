import commonModule from "@/store/common";
import modalModule from "@/store/modal";
import utilityModule from "@/store/utility";
import actions from "@/store/top/top-init/actions";
import getters from "@/store/top/top-init/getters";
import mutations from "@/store/top/top-init/mutations";

const topInitModule = {
  namespaced: true,
  modules: {
    common: commonModule,
    modal: modalModule,
    utility: utilityModule,
  },
  state: {
    hallInitSetting: {},
    hallSettingMaster: {},
    mediaAccount: {},
  },
  getters,
  mutations,
  actions,
};

export default topInitModule;
