export const SET_NEWS_DATA = "SET_NEWS_DATA";
export const SET_NEWS_MASTER = "SET_NEWS_MASTER";

export const SET_LINK_DATA = "SET_LINK_DATA";
export const REMOVE_LINK_CAUTION = "REMOVE_LINK_CAUTION";

export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_ACCOUNT_LOG = "SET_ACCOUNT_LOG";
export const SET_ACCOUNT_LOGIN = "SET_ACCOUNT_LOGIN";

export const SET_BENEFIT_DATA = "SET_BENEFIT_DATA";
export const SET_BENEFIT_COUNT = "SET_BENEFIT_COUNT";

export const SET_HALL_BENEFIT_DATA = "SET_HALL_BENEFIT_DATA";

export const SET_IMAGE_DATA = "SET_IMAGE_DATA";
export const SET_IMAGE_COUNT = "SET_IMAGE_COUNT";

export const SET_FAIR_COUNT_MONTHLY = "SET_FAIR_COUNT_MONTHLY";
export const SET_FAIR_LIST = "SET_FAIR_LIST";
export const SET_CALENDAR = "SET_CALENDAR";
export const SET_MEDIA = "SET_MEDIA";
export const SET_SCHEDULE = "SET_SCHEDULE";

export const SET_LOADING_TYPE = "SET_LOADING_TYPE";

export const REMOVE_LOG = "REMOVE_LOG";
export const SHOW_LOG_MODAL = "SHOW_LOG_MODAL";

export const SET_IS_AUTHORIZED_UNIT = "SET_IS_AUTHORIZED_UNIT";

// プラン
export const UPDATE_PLAN_DATA = "UPDATE_PLAN_DATA";
export const UPDATE_PLAN_COUNT = "UPDATE_PLAN_COUNT";
