<template>
  <div class="c">
    <div class="c_h" :style="moveLeft">
      <div v-if="needFilter">
        <p class="c_filter" v-if="hasGroups">
          <span class="c_filterLabel">表示するグループ：</span>
          <input-selectbox v-model="filteredGroup" :can-select-null="true" :options="groups" />
        </p>
        <p class="c_filter">
          <i class="material-icons">search</i>
          <filter-input
            class="c_filterInput"
            name="keyword"
            placeholder="フェア名・フェアID"
            @update="filterChangeHandler"
          />
        </p>
      </div>
    </div>
    <div
      class="c_dr"
      v-for="(dates, key, index) in calendar"
      :id="`calendar_month-${index}`"
      :key="index"
    >
      <ul class="c_monthNav">
        <li class="material-icons c_monthArrow" v-if="index > 0" @click="scrollToMonth(index - 1)">
          keyboard_arrow_left
        </li>
        <li class="c_month fontNum">
          {{ dates.length < 3 ? "" : key.replace("-", ".") }}
        </li>
        <li
          class="material-icons c_monthArrow"
          v-if="index < 11 && dates.length > 4"
          @click="scrollToMonth(index + 1)"
        >
          keyboard_arrow_right
        </li>
      </ul>
      <header-day-cell
        class="c_days"
        v-for="(date, index) in dates"
        :key="index"
        :date="date"
        :is-weekend-bg="isWeekendBg"
      />
    </div>
  </div>
</template>

<script>
import FilterInput from "@/components/atoms/common/FilterInput.vue";
import HeaderDayCell from "@/components/atoms/common/HeaderDayCell.vue";
import InputSelectbox from "@/components/atoms/fair-schedule/InputSelectbox.vue";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    FilterInput,
    HeaderDayCell,
    InputSelectbox,
  },
  data() {
    return {};
  },
  props: {
    calendar: Object,
    groups: {
      type: Object,
      default: () => {},
    },
    isWeekendBg: {
      type: Boolean,
      default: true,
    },
    moveLeft: Object,
    needFilter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["master", "recommendedDaily", "filter"]),
    hasGroups() {
      return Object.keys(this.groups).length > 0;
    },
    filteredGroup: {
      get() {
        return this.filter.group.length === 1 ? this.filter.group[0] : null;
      },
      set(groupId) {
        const value = groupId ? [groupId] : [];
        this.updateFilter({ label: "group", value });
      },
    },
  },
  methods: {
    ...mapActions(["updateFilter"]),
    filterChangeHandler(label, value) {
      this.updateFilter({ label, value });
    },
    scrollToMonth(index) {
      this.$emit("scrollToMonthHandler", index);
    },
  },
};
</script>

<style scoped lang="scss">
.c {
  align-items: center;
  background-color: $BG_COLOR_BOX;
  border-bottom: 1px solid $SCHEDULE_BORDER_DARK_COLOR;
  box-sizing: content-box;
  display: flex;
  height: $SCHEDULE_HEADER_HEIGHT;
  padding-left: $SCHEDULE_ROW_LABEL_WIDTH;
  position: relative;
  @media screen and (max-width: $MAIN_S_WIDTH) {
    padding-left: $SCHEDULE_ROW_LABEL_WIDTH_SMALL;
  }
  &_h {
    align-items: center;
    background: #fff;
    border-right: 1px solid $SCHEDULE_BORDER_DARK_COLOR;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    left: 0;
    padding: 0 $SUB_GRID * 2;
    position: absolute;
    top: 0;
    width: $SCHEDULE_ROW_LABEL_WIDTH;
    z-index: 3 + $LAYER_INOPERABLE;
    @media screen and (max-width: $MAIN_S_WIDTH) {
      width: $SCHEDULE_ROW_LABEL_WIDTH_SMALL;
    }
  }
  &_filter {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    &:nth-child(2) {
      margin-top: $SUB_GRID * 1.5;
    }
    &Label {
      color: $COLOR_KEY;
      font-size: 11px;
      font-weight: bold;
    }
    .inputSelectbox_wrapper {
      margin: 0;
      width: $GRID * 10;
    }
    .material-icons {
      color: $COLOR_KEY;
    }
  }
  &_filterInput {
    margin-left: $SUB_GRID;
    width: 20em;
    @media screen and (max-width: $MAIN_S_WIDTH) {
      width: 15em;
    }
    .filterInput {
      font-size: 12px;
      padding: 4px $SUB_GRID * 2;
    }
  }
  &_dr {
    box-sizing: content-box;
    display: flex;
    border-left: $SCHEDULE_MONTH_BORDER;
    height: $SCHEDULE_HEADER_DAY_HEIGHT;
    padding-top: $SCHEDULE_HEADER_MONTH_HEIGHT;
    position: relative;
  }
  &_monthNav {
    align-items: center;
    border-bottom: 1px solid $SCHEDULE_BORDER_COLOR;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    list-style: none;
    left: 0;
    padding: 0 $SUB_GRID * 2 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
  }
  &_monthArrow {
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid $COLOR_KEY;
    color: $COLOR_KEY;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    padding: 0;
    transition: all 800ms;
    &:hover {
      background-color: $COLOR_KEY;
      color: #fff;
      transition: all 200ms;
    }
  }
  &_month {
    // background-color: rgba($COLOR_KEY, 0.1);
    // background-color: $BG_COLOR_SUB;
    color: $COLOR_KEY;
    font-size: 20px;
    height: $SCHEDULE_HEADER_MONTH_HEIGHT;
    line-height: $SCHEDULE_HEADER_MONTH_HEIGHT;
    padding: 0 $SUB_GRID * 2;
  }
}
</style>
