<template>
  <svg class="rotate" :height="radius * 2" :width="radius * 2">
    <circle class="bg" :stroke-width="stroke" :r="normalizedRadius" :cx="radius" :cy="radius" />
    <circle
      :class="classType"
      :stroke-dasharray="circumference + ' ' + circumference"
      :style="{ strokeDashoffset }"
      :stroke-width="stroke"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
  </svg>
</template>

<script>
export default {
  props: {
    alertType: Boolean,
    alertValue: { type: Number, default: 95 },
    progress: Number,
    radius: { type: Number, default: 83 },
    stroke: { type: Number, default: 6 },
    warningValue: { type: Number, default: 90 }
  },
  data: function() {
    const normalizedRadius = this.radius - this.stroke / 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    return {
      normalizedRadius,
      circumference
    };
  },
  computed: {
    classType: function() {
      if (this.alertType) {
        if (this.progress >= this.alertValue) {
          return "alert";
        } else if (this.progress >= this.warningValue) {
          return "warning";
        } else {
          return "normal";
        }
      } else {
        return "";
      }
    },
    strokeDashoffset: function() {
      return this.circumference - (this.progress / 100) * this.circumference;
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
circle {
  fill: transparent;
  stroke: $COLOR_KEY;
  stroke-linecap: round;
  transform-origin: 50% 50%;
  &.bg {
    fill: rgba(255, 255, 255, 0.4);
    stroke: #dbdcdc;
  }
  &.alert {
    stroke: $COLOR_ALERT;
  }
  &.normal {
    stroke: $COLOR_SUBMIT;
  }
  &.warning {
    stroke: $COLOR_WARNING;
  }
}
.rotate {
  transform: rotate(-90deg);
}
</style>
