<template>
  <div class="imageDetail">
    <a class="imageDetailLink" :href="`/image-detail/${data.id}`">
      <fair-img :path="data.urls.thumb" height="160px" width="160px" />
      <period-date
        :date="data.license_valid_until"
        :isCenter="true"
        :status="data.license_status"
        :status-master="master.license_status"
      />
    </a>
    <inoperable-cover v-if="inSync" text :is-small="true" />
  </div>
</template>

<script>
import FairImg from "@/components/atoms/common/FairImg.vue";
import PeriodDate from "@/components/atoms/common/PeriodDate.vue";
import InoperableCover from "@/components/atoms/common/InoperableCover.vue";

export default {
  components: {
    FairImg,
    PeriodDate,
    InoperableCover,
  },
  props: {
    data: Object,
    master: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    inSync: function() {
      const s = Object.values(this.data.link_statuses).some(
        (status) => status == 1
      );
      return s;
    },
  },
};
</script>

<style scoped lang="scss">
.imageDetail {
  margin-top: $GRID * 0.25;
  position: relative;
  &Link {
    display: block;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
