<template>
  <job-main v-if="!loading" />
</template>

<script>
import JobMain from "@/components/organisms/job/JobMain.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    JobMain,
  },
  computed: {
    ...mapState("common", { loading: (state) => state.loading }),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("job", { init: "getInitialData" }),
  },
};
</script>

<style scoped lang="scss"></style>
