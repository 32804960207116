<template>
  <section class="linkCautions">
    <div v-if="hasCaution">
      <h3 class="linkCautions_title linkCautions_title-hasCaution">
        <span class="linkCautions_title_number fontNum">{{ count }}</span
        >件のご対応お願いします！
      </h3>
      <ul class="linkCautions_list" v-if="hasCaution">
        <transition-group name="fade">
          <li
            class="linkCautions_item"
            v-for="(item, index) in linkCautions"
            v-show="isOpened || index < max"
            :key="index"
          >
            <link-caution :caution="item" />
          </li>
        </transition-group>
      </ul>
      <button
        class="linkCautions_toggleBtn"
        v-if="count > max"
        @click="toggleList"
      >
        {{ btnText }}
      </button>
    </div>
    <div v-else>
      <h3 class="linkCautions_title linkCautions_title-normally">
        <i class="linkCautions_title_icon material-icons"
          >check_circle_outline</i
        >
        <span>全媒体へ正常に連携されています</span>
      </h3>
    </div>
  </section>
</template>

<script>
import LinkCaution from "@/components/organisms/top/link/LinkCaution.vue";
import VueScrollTo from "vue-scrollto";

import { mapGetters } from "vuex";

export default {
  components: {
    LinkCaution,
  },
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    ...mapGetters(["linkCautions"]),
    count() {
      return this.linkCautions.length;
    },
    hasCaution() {
      return this.count > 0;
    },
    btnText() {
      return this.isOpened ? "閉じる" : "全て表示する";
    },
  },
  created() {
    this.max = 3; // 初期化時の最大表示数
  },
  methods: {
    toggleList() {
      this.isOpened = !this.isOpened;
      if (!this.isOpened) {
        VueScrollTo.scrollTo("#linkUnit");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.linkCautions {
  &_title {
    font-size: 1rem;
    &-hasCaution {
      font-weight: bold;
      margin-bottom: $GRID;
    }
    &-normally {
      color: $COLOR_SUB_TEXT;
      font-weight: normal;
    }
    &_number {
      font-size: $GRID * 2;
    }
    &_icon {
      color: $COLOR_SUBMIT;
      padding: 6px;
    }
  }
  &_item {
    list-style: none;
    margin-top: $SUB_GRID * 2;
    &:first-child {
      margin-top: 0;
    }
  }
  &_toggleBtn {
    @include btn(rgba($COLOR_SUB_LIGHT_TEXT, 0.1));
    background: #fff;
    border: 1px solid $COLOR_SUB_LIGHT_TEXT;
    border-radius: 18px;
    box-shadow: $DEPTH_LV_0;
    font-size: $FONT_SIZE_SS;
    margin-top: $GRID;
    padding: $SUB_GRID 0;
    text-align: center;
    width: $GRID * 7;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
