<template>
  <section class="expired-list">
    <expired-unit v-if="isAuthorizedUnit.image.view" type="image" />
    <expired-unit v-if="isAuthorizedUnit.benefit.view" type="benefit" />
    <expired-unit v-if="isAuthorizedUnit.hallBenefit.view" type="hallBenefit" />
    <expired-unit v-if="isAuthorizedUnit.plan.view" type="plan" />
  </section>
</template>

<script>
import { mapState } from "vuex";

import ExpiredUnit from "@/components/organisms/top/expired/ExpiredUnit.vue";

export default {
  components: {
    ExpiredUnit,
  },
  computed: {
    ...mapState(["isAuthorizedUnit"]),
  },
};
</script>

<style scoped lang="scss">
.expired-list {
}
</style>
