import API_CODE from "@/assets/common/ApiCode";
import { WpDateType } from "@/assets/plan/WpDateType";
import { classifyPeriodState } from "@/store/plan-list/helper";

// 掲載・申込・挙式・来館の各期間の期限切れ・間近を確認する
const periodStatus = (dates, diff, mediaIds, unneededWpCheck = false) => {
  const commonDate = dates[API_CODE.mediaCommon];
  const isSetByMedia = !commonDate || (!commonDate.start && !commonDate.end);

  // 媒体個別設定の場合
  if (isSetByMedia) {
    return Object.entries(dates)
      .filter(([mediaId, date]) => {
        const isNull = !date || (!date.start && !date.end);
        if (isNull) {
          return false;
        }
        const id = parseInt(mediaId);
        if (id === API_CODE.media.wp) {
          return !unneededWpCheck;
        }
        return id !== API_CODE.mediaCommon;
      })
      .map(([_, date]) => classifyPeriodState(date.start, date.end, diff));
  }

  // 共通入力 & 複数媒体を選択している場合
  const includesAnyMedia = mediaIds.length > 1;
  if (includesAnyMedia) {
    return [classifyPeriodState(commonDate.start, commonDate.end, diff)];
  }

  // 共通入力 & ウエパのみを選択 & 「ウエパで使用する期間」で選択されていない場合
  // const includesWp = mediaIds.includes(API_CODE.media.wp);
  // if (includesWp && unneededWpCheck) {
  //   return [""];
  // }

  // その他の場合
  return [classifyPeriodState(commonDate.start, commonDate.end, diff)];
};

/**
 * 期限切れ・間近のプラン数を計算
 *
 * @param {array} plans プラン一覧
 * @param {object} monthDiffs 期限切れ通知日数
 */
const countPlan = (plans, monthDiffs) => {
  let soon = 0;
  let over = 0;

  plans.forEach((plan) => {
    if (plan.status !== API_CODE.status.public) return;

    const dateType = plan.date_type[API_CODE.media.wp];
    const mediaIds = plan.media_ids;
    const applicationStatus = periodStatus(
      plan.application_date,
      monthDiffs["application_date"],
      mediaIds,
      dateType !== WpDateType.APPLICATION
    );
    const targetStatus = periodStatus(
      plan.target_date,
      monthDiffs["target_date"],
      mediaIds,
      dateType !== WpDateType.TARGET
    );
    const visitStatus = periodStatus(
      plan.visit_date,
      monthDiffs["visit_date"],
      mediaIds,
      dateType !== WpDateType.VISIT
    );
    const publicationStatus = periodStatus(
      plan.publication_date,
      monthDiffs["publication_date"],
      mediaIds
    );

    const dupulicatedStatus = [
      ...applicationStatus,
      ...targetStatus,
      ...visitStatus,
      ...publicationStatus,
    ];
    const status = [...new Set(dupulicatedStatus)];

    // 掲載・申込・挙式・来館の各期間の期限切れ・間近を確認
    // 各期間で1つでも期限切れがあればその期間は期限切れと判定
    if (status.some((item) => item == "over")) {
      over++;
      return;
    }
    if (status.some((item) => item == "soon")) {
      soon++;
      return;
    }
  });

  return {
    register: plans.length,
    expiredNum: { soon, over },
  };
};

export default countPlan;
