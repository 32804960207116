/**
 * 経過時間のテキスト生成
 * @param {String} date "2019-10-09 16:57:26"
 */
const makeElapsedTimeText = date => {
  let text = "";

  // "2019-10-09 16:57:26" -> "2019-10-09T16:57:26" に変換
  const convertedDate = date.replace(" ", "T");

  const today = new Date().getTime();
  const update = new Date(convertedDate).getTime();

  // 経過時間
  const elapsed = new Date(today - update);

  // 大きい単位から順に表示
  if (elapsed.getUTCFullYear() - 1970) {
    text = elapsed.getUTCFullYear() - 1970 + "年前";
  } else if (elapsed.getUTCMonth()) {
    text = elapsed.getUTCMonth() + "ヶ月前";
  } else if (elapsed.getUTCDate() - 1) {
    text = elapsed.getUTCDate() - 1 + "日前";
  } else if (elapsed.getUTCHours()) {
    text = elapsed.getUTCHours() + "時間前";
  } else if (elapsed.getUTCMinutes()) {
    text = elapsed.getUTCMinutes() + "分前";
  } else {
    text = "たった今";
  }
  return text;
};
export default makeElapsedTimeText;
