<template>
  <div v-if="!loading">
    <info-unit class="topInfo" :news="news" />
    <link-unit class="topLink" />
    <media-unit class="topMedia" v-if="isAuthorizedUnit.media.view" />
    <coremonet-unit class="topCoremonet" />
    <fair-unit class="topFair" v-if="isAuthorizedUnit.fair.view" />
    <div class="topFoot">
      <image-unit v-if="isAuthorizedUnit.image.view" />
      <benefit-unit v-if="isAuthorizedUnit.benefit.view" />
    </div>
    <waiting-unit v-if="isShowWaiting" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import { getCookieAccount } from "@/lib/cookie";

import InfoUnit from "@/components/organisms/top/InfoUnit.vue";
import LinkUnit from "@/components/organisms/top/LinkUnit.vue";
import MediaUnit from "@/components/organisms/top/MediaUnit.vue";
import CoremonetUnit from "@/components/organisms/top/CoremonetUnit.vue";
import FairUnit from "@/components/organisms/top/FairUnit.vue";
import ImageUnit from "@/components/organisms/top/ImageUnit.vue";
import BenefitUnit from "@/components/organisms/top/BenefitUnit.vue";
import WaitingUnit from "@/components/organisms/top/WaitingUnit.vue";

export default {
  components: {
    InfoUnit,
    LinkUnit,
    MediaUnit,
    CoremonetUnit,
    FairUnit,
    ImageUnit,
    BenefitUnit,
    WaitingUnit,
  },
  computed: {
    ...mapState(["news", "isAuthorizedUnit", "roleCode"]),
    ...mapState("common", { loading: (state) => state.loading }),
    ...mapGetters("common", ["role"]),
    isShowWaiting() {
      if (!this.role) return false;
      return this.role == this.roleCode.waiting;
    },
  },
  methods: {
    ...mapActions({
      init: "getInitialData",
    }),
    ...mapActions("modal", ["showErrorModal"]),
  },
  mounted() {
    const { userable_type, hall_id } = getCookieAccount();
    if (userable_type === "Hall" || hall_id !== null) {
      this.init();
    }
  },
};
</script>

<style lang="scss">
.topBlock {
  @include Box();
  box-sizing: border-box;
  overflow: hidden;
  width: $MAX_WIDTH;

  .topFoot & {
    width: 100%;
  }
}

.topLink,
.topMedia,
.topCoremonet,
.topFair {
  margin: $GRID * 3.3 0 $GRID * 4.3;
}

.topFoot {
  display: flex;
  justify-content: space-between;
  width: $MAX_WIDTH;
}
</style>
