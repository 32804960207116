<template>
  <dl class="linkCaution" :class="addClass">
    <dt class="linkCaution_title">
      <i class="linkCaution_title_icon material-icons">{{ icon }}</i>
      <p class="linkCaution_title_text">
        {{ title }}
      </p>
      <dl class="linkCaution_media">
        <dt class="linkCaution_media_title">対象媒体</dt>
        <dd class="linkCaution_media_detail">{{ caution.media.name }}</dd>
      </dl>
    </dt>
    <dd class="linkCaution_detail">
      <div class="linkCaution_detail_textArea">
        <p class="linkCaution_detail_text">{{ caution.message }}</p>
        <action-button
          v-if="caution.level === 'notice'"
          class="linkCaution_detail_btn linkCaution_detail_btn-log"
          text="ログを確認済みにする"
          :is-select="true"
          @clickHandler="updateLog()"
        />
        <link-button
          class="linkCaution_detail_btn linkCaution_detail_btn-link"
          v-if="hasLinkBtn"
          :text="linkBtnText"
          :path="linkBtnPath"
        />
      </div>
      <link-caution-logs :logs="caution.logs" :type="caution.type" />
    </dd>
  </dl>
</template>

<script>
import LinkCautionLogs from "@/components/organisms/top/link/LinkCautionLogs.vue";
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import LinkButton from "@/components/atoms/common/LinkButton.vue";
import { getLogTitle } from "@/store/common/helper";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    LinkCautionLogs,
    ActionButton,
    LinkButton,
  },
  props: {
    caution: Object,
  },
  computed: {
    ...mapGetters("common", ["isLogAuthorized"]),
  },
  created() {
    this.setByType();
    this.setTitle();
    this.setClass();
  },
  methods: {
    ...mapActions({
      showConfirm: "showConfirmNoticeModal",
    }),
    setByType() {
      this.icon = "error";
      this.hasLinkBtn = false;
      this.linkBtnText = null;
      this.linkBtnPath = null;

      switch (this.caution.type) {
        case "invalid":
        case "reservation":
        case "daily":
          this.icon = "date_range";
          return;

        case "login":
          if (!this.isLogAuthorized("MediaAccountConnect")) {
            return;
          }
          this.hasLinkBtn = true;
          this.linkBtnText = "パスワードを設定する";
          this.linkBtnPath = "/media-account";
          return;
      }
    },
    setTitle() {
      this.title = this.caution.title;
      const logTitle = getLogTitle(this.caution.target);
      if (!logTitle) {
        return;
      }
      this.title = `${logTitle}の${this.title}`;
    },
    setClass() {
      this.addClass = {
        isNotice: this.caution.level === "notice",
        isWarning: this.caution.level === "warning",
      };
    },
    updateLog() {
      const log = this.caution.logs[0];
      this.showConfirm({ media_id: log.media_id, log_id: log.id });
    },
  },
};
</script>

<style scoped lang="scss">
$mainClass: "linkCaution";
.#{$mainClass} {
  @include Box();
  box-sizing: border-box;
  font-size: $FONT_SIZE_S;

  &_title {
    background-color: $BG_COLOR_WARNING;
  }
  &.isWarning .#{$mainClass}_title {
    background-color: $BG_COLOR_ALERT;
  }
  &_title {
    align-items: center;
    display: flex;
    padding: $SUB_GRID $SUB_GRID * 2;
    &_icon {
      margin-right: $SUB_GRID;
    }
    &_text {
      font-weight: bold;
    }
  }
  &_media {
    align-items: center;
    border: 1px solid $COLOR_TEXT;
    border-radius: 2px;
    display: flex;
    font-size: $FONT_SIZE_SS;
    line-height: 1;
    margin-left: $SUB_GRID;
    padding: 3px;
    &_title {
      &:after {
        content: "：";
        display: inline;
      }
    }
  }
  &_detail {
    padding: $GRID;
    &_text {
      white-space: break-spaces;
    }
    &_btn {
      margin: $SUB_GRID 0 0 auto;
      &-log {
        width: $GRID * 9 !important;
      }
    }
  }
  &_logs {
    margin-top: $GRID;
  }
  &_log {
    list-style: none;
    margin-top: $SUB_GRID;
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
