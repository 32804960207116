/**
 * Top - getters
 */

import API_CODE from "@/assets/common/ApiCode.js";

const getters = {
  linkCautions: (state) => {
    return [...state.link.cautions];
  },
  countsByType: (state) => (type) => {
    return state[type].count;
  },
  countCoremonetFairs: ({ loading, fair }) => {
    if (loading.fairList) return "-";
    return fair.list.filter((_fair) =>
      _fair.media_ids.includes(API_CODE.media.homepage)
    ).length;
  },
  countCoremonetPlans: ({ loading, plan }) => {
    if (loading.plan) return "-";
    return plan.list.filter((_plan) =>
      _plan.media_ids.includes(API_CODE.media.homepage)
    ).length;
  },
};

export default getters;
