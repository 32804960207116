<template>
  <div class="topInit" v-if="!loading">
    <page-title icon="flight_takeoff" title="初期設定準備" />
    <step-unit
      button-text="変更する"
      name="パスワードの変更"
      num="1"
      path="/master-account"
      text="初期パスワードを変更してください"
      :is-checked="isPasswordChanged"
    />
    <step-unit
      button-text="設定する"
      name="媒体アカウント設定"
      num="2"
      path="/media-account"
      text="連携する媒体の ID とパスワードを入力してください"
      :is-checked="isMediaAccountSet"
      :is-disabled="isAccountCreated"
    />
    <step-unit
      class="option"
      button-text="変更する"
      name="残席のデフォルト設定"
      path="/hall-init-setting"
      text="新規登録時の残席設定をご確認の上、必要な場合は変更してください"
      :is-optional="true"
      :sub-text="subText"
      :is-disabled="isAccountCreated"
    />
    <div class="bottom">
      <span class="bottomText">初期設定準備が完了すると、弊社で初期設定を開始いたします</span>
      <action-button
        class="bottomButton"
        text="初期設定 準備完了"
        :is-disabled="!isPasswordChanged || !isMediaAccountSet"
        :is-large="true"
        :is-save="true"
        @clickHandler="complete"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import ActionButton from "@/components/atoms/common/ActionButton.vue";
import PageTitle from "@/components/atoms/common/PageTitle.vue";
import StepUnit from "@/components/molecules/top/top-init/StepUnit.vue";

export default {
  components: {
    ActionButton,
    PageTitle,
    StepUnit,
  },
  computed: {
    ...mapState("common", { loading: (state) => state.loading }),
    ...mapGetters({
      hall: "common/hall",
      hallInitSetting: "topInit/hallInitSetting",
      hallSettingMaster: "topInit/hallSettingMaster",
      mediaAccount: "topInit/mediaAccount",
    }),
    isPasswordChanged() {
      return this.hall.password_changed_at !== null;
    },
    isMediaAccountSet() {
      return Object.values(this.mediaAccount).every(
        ({ in_usage, login_status }) => !in_usage || login_status === 2
      );
    },
    isAccountCreated() {
      return this.hall.account_created_at !== null;
    },
    subText() {
      return `ゼクシィ：${this.zexyStock}<br />婚スタ・ウエパ・マイナビ：${this.stock}`;
    },
    zexyStock() {
      const { default_stock_available_num } = this.hallInitSetting;
      return default_stock_available_num ? `${default_stock_available_num}枠` : "";
    },
    stock() {
      const { default_stock_availability } = this.hallInitSetting;
      if (default_stock_availability) {
        const availabilityType = this.hallSettingMaster.availability_num;
        return availabilityType[default_stock_availability];
      }
      return "";
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    ...mapActions({
      init: "topInit/getInitialData",
      ready: "topInit/ready",
    }),
    complete() {
      this.ready();
    },
  },
};
</script>

<style scoped lang="scss">
.topInit {
  font-size: 12px;
  .bottom {
    color: $COLOR_SUB_TEXT;
    margin-top: $GRID * 2;
    text-align: center;
    &Button {
      font-size: 12px;
      margin: $GRID * 2 auto auto;
    }
  }
  .option {
    margin-top: $GRID * 2;
  }
}
</style>
