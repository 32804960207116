<template>
  <ul class="hallListNav">
    <li
      class="hallListNav_item"
      v-for="v in pageNav"
      :key="v.dir"
      :class="{ isDisabled: v.dir !== 'top' && (isRestricted || v.isRestricted) }"
    >
      <button type="button" class="hallListNav_link" @click="onClick(v.dir)">
        <span class="material-icons">{{ v.icon }}</span>
        <span class="hallListNav_txt">{{ v.hallListName || v.name }}</span>
      </button>
    </li>
    <li class="hallListNav_item">
      <action-button
        class="actionButton"
        :is-disabled="false"
        :is-save="false"
        text="サービスを設定"
        @clickHandler="$emit('serviceModalOpen')"
      />
    </li>
  </ul>
</template>

<script>
import { PAGE_CONFIG, selectHall } from "@/assets/common/PageConfig.js";
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ActionButton,
  },
  props: {
    id: Number,
    isRestricted: Boolean,
  },
  computed: {
    ...mapGetters("common", ["isAuthorized"]),
    pageNav() {
      let nav = PAGE_CONFIG.filter((v) => "hallListOrder" in v);
      nav.sort(function (a, b) {
        return a.hallListOrder > b.hallListOrder ? 1 : -1;
      });
      nav.map((v) => {
        v.isRestricted = v.permission ? !this.isAuthorized(v.permission) : false;
        return v;
      });
      return nav;
    },
  },
  methods: {
    onClick(dir) {
      selectHall(this.id);
      window.location.href = dir == "top" ? "/" : "/" + dir;
    },
  },
};
</script>

<style scoped lang="scss">
.hallListNav {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: $GRID 0 ($GRID -$SUB_GRID);
  width: $GRID * 10 * 3;
  &_item {
    margin: 0 $GRID $SUB_GRID 0;
    width: $GRID * 9;
    &:nth-child(3n) {
      width: $GRID * 8.25;
    }
    &.isDisabled {
      cursor: not-allowed;
    }
    .actionButton {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      font-size: 12px;
      height: $GRID * 1.5;
      background: #22ab38;
    }
  }
  &_link {
    -webkit-appearance: none;
    border: none;
    border-radius: $BOX_RADIUS;
    color: $COLOR_KEY;
    display: block;
    font-size: 12px;
    outline: none;
    padding: 3px $SUB_GRID;
    text-align: left;
    width: 100%;
    .isDisabled & {
      opacity: 0.5;
      pointer-events: none;
    }
    &:hover {
      background: $COLOR_KEY;
      color: #fff;
    }
  }
  &_txt,
  .material-icons {
    vertical-align: middle;
  }
  .material-icons {
    font-size: 21px;
    margin-right: $SUB_GRID * 2;
  }
}
</style>
