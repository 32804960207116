<template>
  <div class="benefitUnit">
    <block-title icon="stars" title="フェア特典管理">
      <template #btn>
        <link-btn text="特典設定" href="/benefit/" />
      </template>
    </block-title>
    <div class="topBlock">
      <div class="benefitUnitDetail">
        <text-count
          class="benefitUnitCountAll"
          text="設定特典数"
          :count="counts.register"
        />
        <div class="benefitUnitDetailInr">
          <text-count
            class="benefitUnitCountGroup"
            v-for="v of benefit.count.group"
            :key="v.id"
            :text="title[v.id]"
            :count="v.count"
          />
        </div>
      </div>
      <p v-if="isEmpty" class="benefitUnitEmpty">
        適用期間が終了間近、
        <br />または終了した特典はありません
      </p>
      <div v-else class="benefitUnitList">
        <p class="benefitUnitListTit">
          <i class="material-icons">warning</i>適用期間を確認してください
        </p>
        <div class="benefitUnitListInr">
          <benefit-detail
            v-for="v of benefit.list"
            :key="v.id"
            :data="v"
            :master="benefit.master"
          />
          <a href="/benefit/?in_use&license=all" class="more">
            <i class="material-icons" v-if="isMore">more_horiz</i>
          </a>
        </div>
        <expires-soon-count
          :count="counts.expiredNum.soon + counts.expiredNum.over"
          unit="個"
          href="/benefit/?in_use&license=all"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BlockTitle from "@/components/molecules/top/BlockTitle.vue";
import BenefitDetail from "@/components/molecules/top/BenefitDetail.vue";
import ExpiresSoonCount from "@/components/molecules/top/ExpiresSoonCount.vue";
import TextCount from "@/components/atoms/top/TextCount.vue";
import LinkBtn from "@/components/atoms/common/LinkButton.vue";
import { GROUP_TITLE } from "@/assets/benefit/TextConfig.js";
// Store
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    BlockTitle,
    BenefitDetail,
    ExpiresSoonCount,
    TextCount,
    LinkBtn,
  },
  computed: {
    ...mapState(["benefit"]),
    ...mapGetters(["countsByType"]),
    isEmpty: function () {
      return (
        this.counts.expiredNum.soon == 0 && this.counts.expiredNum.over == 0
      );
    },
    isMore: function () {
      return (
        this.counts.expiredNum.soon + this.counts.expiredNum.over >
        this.benefit.display
      );
    },
  },
  created() {
    this.title = GROUP_TITLE;
    this.counts = this.countsByType("benefit");
  },
};
</script>

<style scoped lang="scss">
.benefitUnit {
  width: $GRID * 21;
  &Detail {
    @include gradientMain();
    padding: $GRID;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  &Count {
    &All {
      align-items: center;
      background: rgba(255, 255, 255, 0.4);
      border-radius: $BOX_RADIUS * 0.5;
      box-shadow: $DEPTH_LV_0;
      height: $GRID * 4.8;
      padding: 0 $SUB_GRID * 2;
      width: $GRID * 9.5;
      ::v-deep .blockCountNum {
        font-size: 32px;
      }
    }
    &Group {
      width: $GRID * 8.5;
      ::v-deep .blockCountNum {
        font-size: 20px;
      }
      & + & {
        margin-top: $SUB_GRID;
      }
    }
  }
  &Empty {
    color: $COLOR_KEY;
    font-size: 14px;
    padding: $GRID * 2;
    text-align: center;
  }
  &List {
    padding: $GRID $GRID $GRID * 0.5;
    &Tit {
      border-bottom: 1px solid $COLOR_WARNING;
      color: $COLOR_KEY;
      font-size: 14px;
      margin-bottom: $GRID;
      padding-bottom: 7px;
      .material-icons {
        color: $COLOR_WARNING;
        font-size: 20px;
        margin-right: 4px;
      }
    }
    &Inr {
      margin-bottom: $GRID * 1.3;
      position: relative;
    }
    .more {
      background: $COLOR_KEY;
      border-radius: 50%;
      bottom: -12px;
      color: #fff;
      display: block;
      left: 50%;
      line-height: 1;
      margin-left: -12px;
      overflow: hidden;
      position: absolute;
      width: $GRID * 1.5;
      z-index: $LAYER_INOPERABLE + 1;
      &:hover {
        box-shadow: $DEPTH_LV_2;
        opacity: 0.8;
      }
    }
  }
}
</style>
