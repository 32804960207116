<template>
  <section class="coremonet-promotion">
    <div class="coremonet-promotion__row">
      <p class="coremonet-status__circle">{{ status[statusCode] }}</p>
      <p class="coremonet-status__note">
        現在、コレモネっと（HP連携）が{{ status[statusCode] }}です
      </p>
    </div>
    <div class="coremonet-promotion__row">
      <span>
        <img class="img-corekun" src="/img/coremone_logo_login.png" />
      </span>
      <span class="balloon balloon-left">
        コレモネっとは、コレモネから貴社HPの「フェア」「プラン」ページの更新が一括でできる便利な機能だよ！
      </span>
    </div>
    <div class="coremonet-promotion__row reverse">
      <span>
        <img class="img-monechan" src="/img/coremone_logo_login.png" />
      </span>
      <span class="balloon balloon-right">
        {{ promotion[statusCode] }}
      </span>
    </div>
    <div class="coremonet-promotion__row center">
      <p class="coremonet-contact__info">
        <span class="coremonet-contact__tel">{{ master.tel }}</span>
        <span>受付時間</span>
        <span>{{ master.time }}</span>
      </p>
      <p>
        <action-button
          :is-large="true"
          text="お問い合わせ"
          class="coremonet-contact__button"
          @clickHandler="showModalContact"
        />
      </p>
    </div>
  </section>
</template>

<script>
import MASTER_CONFIG from "@/assets/common/MasterConfig";
import { serviceStatus } from "@/assets/hall-admin/coremonet-config";
import { mapGetters, mapActions } from "vuex";

import ActionButton from "@/components/atoms/common/ActionButton.vue";

export default {
  components: {
    ActionButton,
  },
  data() {
    return {
      master: MASTER_CONFIG,
      status: {
        [serviceStatus.NOT_IN_SERVICE]: "未設定",
        [serviceStatus.SUSPENDED]: "停止中",
      },
      promotion: {
        [serviceStatus.NOT_IN_SERVICE]: "少しでも興味があれば連絡してね！",
        [serviceStatus.SUSPENDED]: "もう一度連携したい場合は連絡してね！",
      },
    };
  },
  computed: {
    ...mapGetters({
      statusCode: "common/coremonetStatus",
    }),
  },
  methods: {
    ...mapActions({ showModalContact: "utility/contact/showModalContact" }),
  },
};
</script>

<style scoped lang="scss">
.coremonet-promotion {
  @include Box();
  box-sizing: border-box;
  padding: $GRID;
  width: $GRID * 32;
  font-size: $FONT_SIZE_S;

  &__row {
    display: flex;
    gap: $GRID;
    align-items: center;

    &.reverse {
      flex-direction: row-reverse;
    }

    &.center {
      justify-content: center;
    }
  }

  &__row + &__row {
    margin-top: $SUB_GRID;
  }
}

.coremonet-status {
  &__circle,
  &__note {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__circle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    color: #fff;
    background-color: $COLOR_SUB_LIGHT_TEXT;
    text-align: center;
  }

  &__note {
    font-size: 14px;
  }
}

.img {
  &-corekun,
  &-monechan {
    width: 65px;
    height: 60px;
    object-fit: cover;
  }
  &-corekun {
    object-position: 0 0;
  }
  &-monechan {
    object-position: 100% 0;
  }
}

.balloon {
  position: relative;
  display: inline-block;
  padding: 7px 10px;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  background: #fff;
  border: solid 3px #555;
  box-sizing: border-box;
  border-radius: $SUB_GRID;

  &-left {
    &:before {
      content: "";
      position: absolute;
      top: 35%;
      left: -24px;
      margin-top: -12px;
      border: 12px solid transparent;
      border-right: 12px solid #fff;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      top: 35%;
      left: -30px;
      margin-top: -14px;
      border: 14px solid transparent;
      border-right: 14px solid #555;
      z-index: 1;
    }
  }

  &-right {
    &:before {
      content: "";
      position: absolute;
      top: 45%;
      right: -18px;
      margin-top: -12px;
      border: 10px solid transparent;
      border-left: 10px solid #fff;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      top: 45%;
      right: -22px;
      margin-top: -12px;
      border: 10px solid transparent;
      border-left: 10px solid #555;
      z-index: 1;
    }
  }
}

.coremonet-contact {
  &__info {
    display: flex;
    flex-direction: column;
    color: $COLOR_KEY;
  }

  &__tel {
    font-size: $SUB_GRID * 5;
    font-weight: 500;
  }

  &__button {
  }
}
</style>
