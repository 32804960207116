<template>
  <div id="page">
    <page-nav dir="top" />
    <router-view class="mainBlock" />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import PageNav from "@/components/organisms/common/PageNavigation.vue";

export default {
  components: {
    PageNav,
  },
  created() {
    this.checkPagePermission();
  },
  methods: {
    ...mapActions({
      checkPagePermission: "common/checkPagePermission",
    }),
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/common.scss";
</style>
