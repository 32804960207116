<template>
  <section id="linkUnit" class="linkUnit">
    <block-title icon="sync_alt" title="連携状態" />
    <div class="topBlock linkUnit__main">
      <link-cautions class="linkUnit__cautions linkUnit__section" />
      <expired-list class="linkUnit__expired linkUnit__section" />
    </div>
  </section>
</template>

<script>
import BlockTitle from "@/components/molecules/top/BlockTitle.vue";
import LinkCautions from "@/components/organisms/top/link/LinkCautions.vue";
import ExpiredList from "@/components/organisms/top/expired/ExpiredList.vue";

export default {
  components: {
    BlockTitle,
    LinkCautions,
    ExpiredList,
  },
};
</script>

<style scoped lang="scss">
.linkUnit {
  &__main {
    display: flex;
    flex: 1 0 auto;
    padding: $GRID;
  }

  &__section {
    & + .linkUnit__section {
      border-left: 1px solid $COLOR_DISABLE_TEXT;
      margin-left: $GRID;
      padding-left: $GRID;
    }
  }

  &__cautions {
    width: $GRID * 38;
  }

  &__expired {
    width: calc(100% - #{$GRID} * (38 + 1));
  }
}
</style>
