const mutations = {
  /**
   * 初期データを反映する
   */
  // お知らせ
  SET_NEWS_DATA(state, { data }) {
    state.news.list = data;
  },
  SET_NEWS_MASTER(state, { master }) {
    state.news.master = master;
  },
  // 式場一覧
  SET_HALL_DATA(state, { data }) {
    state.hall.list = data;
  },
  SET_HALL_MASTER(state, { master }) {
    state.hall.master = master;
  },
};

export default mutations;
