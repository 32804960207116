<template>
  <div class="expired-unit-wrap">
    <div class="expired-unit" :class="unitClass">
      <div class="expired-unit__header">
        <p class="expired-unit__title">
          <i v-if="icon" class="material-icons">{{ icon }}</i>
          <span>{{ title }}</span>
        </p>
        <link-button
          class="expired-unit__button"
          text="→ 確認する"
          :path="path"
          :is-small="true"
          :is-disabled="isDisabledLink"
        />
      </div>
      <div class="expired-unit__nums">
        <dl class="expired-unit__num" :class="numClass(counts.expiredNum.soon)">
          <dt>期限切れ間近</dt>
          <dd class="value">
            <span class="fontNum">{{ counts.expiredNum.soon }}</span>
            <span>{{ unitText }}</span>
          </dd>
        </dl>
        <dl class="expired-unit__num" :class="numClass(counts.expiredNum.over)">
          <dt>期限切れ</dt>
          <dd class="value">
            <span class="fontNum">{{ counts.expiredNum.over }}</span>
            <span>{{ unitText }}</span>
          </dd>
        </dl>
      </div>
    </div>
    <inoperable-cover
      class="cover"
      v-if="isLoadingPlan"
      :is-small="true"
      text=""
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import LinkButton from "@/components/atoms/common/LinkButton.vue";
import InoperableCover from "@/components/atoms/common/InoperableCover.vue";

const isPlan = (type) => type === "plan";
const isBenefit = (type) => type === "benefit";
const isHallBenefit = (type) => type === "hallBenefit";
const isImage = (type) => type === "image";

export default {
  components: {
    LinkButton,
    InoperableCover,
  },
  props: {
    type: {
      type: String,
      default: "image",
    },
  },
  computed: {
    ...mapGetters(["countsByType"]),
    ...mapState(["loading"]),
    title() {
      if (isPlan(this.type)) return "プラン 期限切れ";
      if (isBenefit(this.type)) return "フェア特典 期限切れ";
      if (isHallBenefit(this.type)) return "式場特典 期限切れ";
      return "画像 版権期限";
    },
    icon() {
      if (isPlan(this.type)) return "cake";
      if (isBenefit(this.type)) return "stars";
      if (isHallBenefit(this.type)) return "stars";
      return "photo";
    },
    path() {
      if (isPlan(this.type)) return "/plan-list/?status=public&expired=all";
      if (isBenefit(this.type)) return "/benefit/?in_use&license=all&group_type=1";
      if (isHallBenefit(this.type)) return "/benefit/?in_use&license=all&group_type=2";
      return "/image-list/?in_use&license=all";
    },
    unitText() {
      return isImage(this.type) ? "枚" : "個";
    },
    unitClass() {
      if (this.isDisabledLink) return null;
      const { soon, over } = this.counts.expiredNum;
      return {
        "expired-unit--alert": over >= 1,
        "expired-unit--warning": over === 0 && soon >= 1,
      };
    },
    isDisabledLink() {
      const { soon, over } = this.counts.expiredNum;
      return soon === 0 && over === 0;
    },
    counts() {
      return this.countsByType(this.type);
    },
    isLoadingPlan() {
      return isPlan(this.type) && this.loading.plan;
    },
  },
  methods: {
    numClass(num) {
      return {
        "expired-unit__num--grayed-out": num === 0,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.expired-unit {
  @include Box();
  background: $BG_COLOR_FLOOR;
  border-radius: $BOX_RADIUS;
  font-size: 12px;
  padding: $GRID * 0.5;
  position: relative;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    align-items: center;
    display: flex;

    .material-icons {
      font-size: 20px;
      margin-right: $SUB_GRID;
    }
  }

  &__button {
    width: $GRID * 5 !important;
  }

  &__nums {
    align-items: center;
    display: flex;
  }

  &__num {
    align-items: center;
    background: rgba(#fff, 0.9);
    border-radius: $BOX_RADIUS * 0.5;
    display: flex;
    font-size: 10.5px;
    justify-content: space-between;
    margin-top: $SUB_GRID;
    padding: $GRID * 0.5;
    width: 100%;

    dt {
      margin-top: 2px;
    }

    dd {
      font-weight: bold;

      .fontNum {
        font-size: 14px;
        margin-right: 2px;
      }
    }

    &--grayed-out {
      opacity: 0.5;
    }

    & + .expired-unit__num {
      margin-left: $SUB_GRID;
    }
  }

  &--alert {
    background: $BG_COLOR_ALERT;
  }

  &--warning {
    background: $BG_COLOR_WARNING;
  }

  &-wrap {
    position: relative;

    & + & {
      margin-top: $GRID;
    }
  }
}
</style>
