<template>
  <div class="waitingUnit">
    <div class="wait">
      <div class="wait_boy">
        <img class="wait_body" src="/img/top/wait-boy.png" />
        <img class="wait_wing left" src="/img/top/wait-boy-left.png" />
        <img class="wait_wing right" src="/img/top/wait-boy-right.png" />
      </div>
      <img class="wait_pc" src="/img/top/wait-pc.png" />
    </div>
    <p class="text">
      現在データを作成しています。
      <br />準備が整うまで、もうしばらくお待ちください。
    </p>
    <p class="contact">
      使用開始時期などご不明な点がございましたら、
      <span class="contact_link" @click="showModalContact">お問い合わせ</span>ください。
    </p>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("utility/contact", ["showModalContact"]),
  },
};
</script>

<style scoped lang="scss">
.waitingUnit {
  @include Box();
  color: $COLOR_KEY;
  height: $GRID * 20;
  text-align: center;
  .contact {
    color: $COLOR_SUB_TEXT;
    font-size: 14px;
    margin-top: $GRID * 1.5;
    &_link {
      cursor: pointer;
      color: $COLOR_SUB_TEXT;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .text {
    font-weight: bold;
  }
  .wait {
    display: inline-block;
    line-height: 1;
    margin: $GRID * 5 0 $GRID * 2.5 $GRID;
    position: relative;
    &_boy {
      animation: watawata 0.5s steps(2, end) infinite;
      bottom: 0;
      left: -28px;
      position: absolute;
    }
    &_body {
      position: relative;
      z-index: 2;
    }
    &_wing {
      position: absolute;
      &.left {
        animation: typing-left 1s steps(4, end) infinite;
        bottom: 5px;
        left: 15px;
        transform-origin: 2px 8px;
        z-index: 3;
      }
      &.right {
        animation: typing-right 1s steps(4, end) 0.5s infinite;
        bottom: 27px;
        right: -18px;
        transform-origin: left;
        z-index: 1;
      }
    }
    &_pc {
      position: relative;
      z-index: 4;
    }
    &::before,
    &::after {
      content: url(/img/top/wait-sweat.png);
      display: block;
      left: -26px;
      position: absolute;
    }
    &::before {
      animation: picha 1s linear infinite;
      top: -30px;
    }
    &::after {
      animation: pichaR 1s 0.2s linear infinite;
      top: -21px;
      transform: rotate(-25deg);
    }
  }
}

@keyframes picha {
  to {
    opacity: 0;
    transform: translateX(-5px);
  }
}
@keyframes pichaR {
  to {
    opacity: 0;
    transform: translateX(-5px) rotate(-25deg);
  }
}

@keyframes watawata {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2px);
  }
}

@keyframes typing-left {
  0% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-20deg);
  }
}
@keyframes typing-right {
  0% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(40deg);
  }
  100% {
    transform: rotate(15deg);
  }
}
</style>
