<template>
  <div class="mediaUnit">
    <block-title icon="settings_input_component" title="媒体設定">
      <template #btn v-if="isAuthorizedUnit.media.edit">
        <link-btn text="媒体設定" href="/media-account/" />
      </template>
    </block-title>
    <div class="topBlock">
      <div class="mediaSet">
        <p class="mediaSet_txt">連携媒体</p>
        <p class="mediaSet_num fontNum">{{ account.count }}</p>
        <p v-if="isAuthorizedUnit.login.view" class="infoLogin">
          <i class="material-icons">account_circle</i>媒体の管理画面へ
          <br />ログインします
        </p>
      </div>
      <media-detail
        v-for="v in account.list"
        :key="v.id"
        :data="v"
        :login="login[v.id]"
        :logs="account.log[v.id].logs"
        :is-show-login="isAuthorizedUnit.login.view"
        @showLog="showLog"
      />
    </div>
    <float-log
      :log="log.log"
      :notice="log.notice"
      :title="log.title"
      :is-show="isLogModal"
      :master="account.log_status"
      @close="close"
      @update="showConfirm"
    />
  </div>
</template>

<script>
import BlockTitle from "@/components/molecules/top/BlockTitle.vue";
import MediaDetail from "@/components/molecules/top/MediaDetail.vue";
import LinkBtn from "@/components/atoms/common/LinkButton.vue";
import FloatLog from "@/components/organisms/common/FloatLogWindow.vue";
// Store
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BlockTitle,
    MediaDetail,
    LinkBtn,
    FloatLog,
  },
  props: {},
  data: function () {
    return {
      log: {
        log: [],
        notice: [],
        title: "",
        isAlert: false,
        isShow: false,
      },
    };
  },
  computed: {
    ...mapState(["account", "login", "isAuthorizedUnit", "isLogModal"]),
  },
  methods: {
    ...mapActions({
      showConfirm: "showConfirmNoticeModal",
      showLogModal: "showLogModal",
    }),
    close: function () {
      this.showLogModal(false);
    },
    showLog: function ({ id, name }) {
      this.log.log = this.account.log[id].logs;
      this.log.notice = this.account.log[id].notices;
      this.log.title = name += " ログ";
      this.showLogModal(true);
    },
  },
};
</script>

<style scoped lang="scss">
.topBlock {
  align-items: stretch;
  display: flex;
}
.mediaSet {
  @include gradientMain();
  box-sizing: border-box;
  color: $COLOR_KEY;
  display: flex;
  justify-content: center;
  position: relative;
  width: $GRID * 11 - $SUB_GRID * 6;
  &_txt {
    font-size: 14px;
    left: $GRID;
    position: absolute;
    top: $GRID * 0.9;
  }
  &_num {
    font-size: 70px;
    margin-top: $GRID + 2px;
  }
}
.infoLogin {
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  border-top: 2px solid #e3e3e3;
  bottom: 0;
  color: $COLOR_SUB_LIGHT_TEXT;
  display: flex;
  font-size: 11px;
  height: $GRID * 2.5 + 2;
  justify-content: center;
  left: 0;
  line-height: 1.4;
  position: absolute;
  width: 100%;
  .material-icons {
    margin-right: $SUB_GRID * 2;
  }
}
</style>
