<template>
  <section class="block-coremonet" :class="cssClass">
    <div class="coremonet__status">
      <p class="coremonet__status-circle">
        {{ status }}
      </p>
    </div>
    <div v-if="showCounts" class="coremonet__counts">
      <p class="coremonet__counts-item">
        連携中のフェア<span>{{ countFairs }}件</span>
      </p>
      <p class="coremonet__counts-item">
        連携中のプラン<span>{{ countPlans }}件</span>
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { serviceStatus } from "@/assets/hall-admin/coremonet-config";

export default {
  computed: {
    ...mapGetters({
      statusCode: "common/coremonetStatus",
      countFairs: "countCoremonetFairs",
      countPlans: "countCoremonetPlans",
    }),
    status: function () {
      return {
        [null]: "利用状況\n取得中",
        [serviceStatus.IN_PREPARATION]: "準備中",
        [serviceStatus.IN_SERVICE]: "利用中",
      }[this.statusCode];
    },
    cssClass: function () {
      return {
        [null]: null,
        [serviceStatus.IN_PREPARATION]: "preparing",
        [serviceStatus.IN_SERVICE]: "using",
      }[this.statusCode];
    },
    showCounts: function () {
      return this.statusCode == serviceStatus.IN_SERVICE;
    },
  },
};
</script>

<style scoped lang="scss">
.block-coremonet {
  @include Box();
  box-sizing: border-box;
  display: inline-flex;
  height: 100px;
}
.coremonet__status {
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coremonet__status-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: #fff;
  background-color: $COLOR_SUB_LIGHT_TEXT;
  display: flex;
  justify-content: center;
  font-size: $FONT_SIZE_S;
  white-space: pre-line;
  text-align: center;

  .using & {
    background-color: $COLOR_SUBMIT;
    align-items: center;
    white-space: normal;
  }

  .preparing & {
    background-color: $COLOR_WARNING;
    align-items: center;
    white-space: normal;
  }
}

.coremonet__counts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 250px;
  padding: 5px 15px;
  border-left: 1px solid #ececec;
}

.coremonet__counts-item {
  width: 100%;
  height: 35px;
  border-radius: 3px;
  padding: 5px 15px;
  font-size: $FONT_SIZE_S;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  .using & {
    background-color: rgba($COLOR_SUBMIT, 0.3);
  }

  .preparing & {
    background-color: rgba($COLOR_WARNING, 0.3);
  }

  & > span {
    font-size: $FONT_SIZE_M;
    font-weight: 500;
  }
}
</style>
