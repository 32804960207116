<template>
  <div class="imageUnit">
    <block-title icon="photo_library" title="画像管理">
      <template #btn>
        <link-btn text="画像設定" href="/image-list/" />
      </template>
    </block-title>
    <div class="topBlock" :class="{ isEmpty }">
      <div class="imageUnitDetail">
        <div class="imageUnitRegister">
          <progress-ring
            class="imageUnitRegister_ring"
            :alert-type="false"
            :progress="percentage"
          />
          <dl class="imageUnitRegister_textBox">
            <dt class="imageUnitRegister_text">登録画像枚数</dt>
            <dd>
              <p class="imageUnitRegister_num">
                <span class="numer fontNum">{{ counts.register }}</span>
                <span class="denom fontNum">{{ counts.max }}</span>
              </p>
            </dd>
          </dl>
        </div>
      </div>
      <p v-if="isEmpty" class="imageUnitEmpty">
        版権期限が間近、
        <br />または終了した画像はありません
      </p>
      <div v-else class="imageUnitList">
        <p class="imageUnitListTit">
          <i class="material-icons">warning</i>版権期限を確認してください
        </p>
        <div class="imageUnitListInr">
          <image-detail
            v-for="v of image.list"
            :key="v.id"
            :data="v"
            :master="image.master"
          />
          <a href="/image-list/?in_use&license=all" class="more">
            <i class="material-icons" v-if="isMore">more_horiz</i>
          </a>
        </div>
        <expires-soon-count
          :count="counts.expiredNum.soon + counts.expiredNum.over"
          unit="枚"
          href="/image-list/?in_use&license=all"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BlockTitle from "@/components/molecules/top/BlockTitle.vue";
import ImageDetail from "@/components/molecules/top/ImageDetail.vue";
import ExpiresSoonCount from "@/components/molecules/top/ExpiresSoonCount.vue";
import ProgressRing from "@/components/atoms/common/ProgressRing.vue";
import LinkBtn from "@/components/atoms/common/LinkButton.vue";
// Store
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    BlockTitle,
    ImageDetail,
    ExpiresSoonCount,
    ProgressRing,
    LinkBtn,
  },
  computed: {
    ...mapState(["image"]),
    ...mapGetters(["countsByType"]),
    isEmpty: function () {
      return (
        this.counts.expiredNum.soon == 0 && this.counts.expiredNum.over == 0
      );
    },
    isMore: function () {
      return (
        this.counts.expiredNum.soon + this.counts.expiredNum.over >
        this.image.display
      );
    },
    percentage: function () {
      return (this.counts.register / this.counts.max) * 100;
    },
  },
  created() {
    this.counts = this.countsByType("image");
  },
};
</script>

<style scoped lang="scss">
.imageUnit {
  width: $GRID * 36;
  .topBlock {
    display: flex;
    justify-content: space-between;
    &.isEmpty {
      align-items: center;
    }
  }
  &Detail {
    @include gradientMain();
    padding: $GRID * 1.5 0;
    width: $GRID * 14;
    .btn {
      margin: $GRID * 1.3 auto 0;
    }
  }
  &Register {
    align-items: center;
    display: flex;
    height: $GRID * 10 + 6;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    width: $GRID * 10 + 6;
    &_ring {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    &_textBox {
      color: $COLOR_KEY;
      position: relative;
      text-align: center;
      width: 100%;
    }
    &_text {
      font-size: 11px;
      padding-left: 29px;
      text-align: left;
    }
    &_num {
      display: inline-block;
      padding-right: 6px;
      position: relative;
      .numer {
        font-size: 34px;
      }
      .denom {
        bottom: -10px;
        font-size: 14px;
        position: absolute;
        right: -25px;
        &::before {
          content: "/";
          display: inline;
          font-size: 11px;
          left: -5px;
          line-height: 1;
          position: absolute;
          top: -5px;
        }
      }
    }
  }
  &Empty {
    color: $COLOR_KEY;
    font-size: 14px;
    text-align: center;
    width: $GRID * 22;
  }
  &List {
    padding: $GRID $GRID - 2 $GRID * 0.5;
    width: $GRID * 22;
    &Tit {
      border-bottom: 1px solid $COLOR_WARNING;
      color: $COLOR_KEY;
      font-size: 14px;
      margin-bottom: $GRID;
      padding-bottom: 7px;
      .material-icons {
        color: $COLOR_WARNING;
        font-size: 20px;
        margin-right: 4px;
      }
    }
    &Inr {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: -$GRID * 0.25 0 $GRID * 1.3;
      position: relative;
    }
    .more {
      background: $COLOR_KEY;
      border-radius: 50%;
      bottom: -12px;
      color: #fff;
      display: block;
      left: 50%;
      line-height: 1;
      margin-left: -12px;
      overflow: hidden;
      position: absolute;
      width: $GRID * 1.5;
      z-index: $LAYER_INOPERABLE + 1;
      &:hover {
        box-shadow: $DEPTH_LV_2;
        opacity: 0.8;
      }
    }
  }
}
</style>
