import API_CODE from "@/assets/common/ApiCode.js";
import MESSAGE_CONFIG from "@/assets/common/MessageConfig.js";

import { httpClient } from "@/lib/httpClient";

import * as commonTypes from "@/store/common/mutationTypes";

const actions = {
  /**
   * 初期値の取得
   */
  async getInitialData({ commit, dispatch, state, getters }) {
    await dispatch("common/checkPagePermission");
    let apiInfo = [];
    if (getters["common/isAuthorized"]({ name: "settings", type: "view" })) {
      apiInfo.push({
        url: "/settings",
        action: "setHallInitSetting",
      });
    }
    if (getters["common/isAuthorized"]({ name: "mediaAccounts", type: "view" })) {
      apiInfo.push({
        url: "/media/accounts",
        action: "setAccount",
      });
    }
    const success = await Promise.all(
      apiInfo.map(
        async (v) =>
          await dispatch(v.action, await dispatch("common/getInitialData", { apiUrl: v.url }))
      )
    );
    if (success.length == 0 || success.every((v) => v == true)) {
      commit("common/" + commonTypes.SET_LOADING_FINISH);
    } else {
      commit("common/" + commonTypes.ERROR);
      dispatch("modal/showErrorModal", {
        text: state.modal.errorModal.text || MESSAGE_CONFIG.error,
      });
    }
  },

  /**
   * 式場基本設定のデータを state に保存
   * @param {Object} response レスポンス
   */
  async setHallInitSetting({ commit }, response) {
    if (!response || !response.data) {
      return false;
    }
    commit("SET_HALL_INIT_SETTING", response.data.data);
    commit("SET_HALL_SETTING_MASTER", response.data.master);
    return true;
  },

  /**
   * 媒体設定のデータを state に保存
   * @param {Object} response レスポンス
   */
  async setAccount({ commit }, response) {
    if (!response || !response.data) {
      return false;
    }
    commit("SET_MEDIA_ACCOUNT", response.data.data);
    return true;
  },

  /**
   * インポート準備完了
   */
  async ready({ commit, dispatch }) {
    commit("modal/setUpdateModal", { isShow: true });
    const url = "/imports/ready";
    const response = await httpClient.post(url).catch((error) => console.log(error));
    if (response && response.status === API_CODE.response.success) {
      commit("modal/setCompleteText", "インポートを開始します");
      dispatch("modal/showCompleteModal", { path: "/" });
    } else {
      const e = {
        isShow: true,
        text: MESSAGE_CONFIG.error,
      };
      dispatch("modal/showErrorModal", e);
    }
  },
};

export default actions;
