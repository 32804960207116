/**
 * Top - actions
 */
import API_CODE from "@/assets/common/ApiCode.js";
import MESSAGE_CONFIG from "@/assets/common/MessageConfig.js";

import { decrypt } from "@/lib/encrypt";
import { httpClient } from "@/lib/httpClient";

import * as commonTypes from "@/store/common/mutationTypes";
import * as types from "@/store/top/mutationTypes";
import { countPlan } from "@/store/top/helper";

const actions = {
  /*
   * 初期データを受け取る
   ***********************************/
  async getInitialData({ commit, dispatch, state, getters }) {
    await dispatch("common/checkPagePermission");
    await dispatch("setUnitPermission");
    let apiInfo = [];
    apiInfo.push({
      url: "/hall/notice",
      action: "setLinkList",
    });
    apiInfo.push({
      url: "/news/published",
      action: "setNewsList",
    });
    if (state.isAuthorizedUnit.benefit.view) {
      apiInfo.push({
        url: "/benefits/expires-soon",
        action: "setInitialBenefitList",
      });
      apiInfo.push({
        url: "/benefits/total-count",
        action: "setInitialBenefitCount",
      });
    }
    if (state.isAuthorizedUnit.hallBenefit.view) {
      apiInfo.push({
        url: "/benefits?for_hall=true&limit_in_use=true",
        action: "setInitialHallBenefitList",
      });
    }
    if (state.isAuthorizedUnit.image.view) {
      apiInfo.push({
        url: "/images/license-expires-soon",
        action: "setInitialImageList",
      });
      apiInfo.push({
        url: "/images/count",
        action: "setInitialImageCount",
      });
    }
    if (state.isAuthorizedUnit.media.view) {
      apiInfo.push({
        url: "/media/accounts",
        action: "setInitialAccounts",
      });
    }
    if (state.isAuthorizedUnit.fair.view) {
      apiInfo.push({
        url: "/fairs/schedules/count/monthly",
        action: "setInitialFairCountMonthly",
      });

      apiInfo.push({
        url: "/fairs",
        action: "setInitialFairList",
      });
    }

    const success = await Promise.all(
      apiInfo.map(
        async (v) =>
          await dispatch(v.action, await dispatch("common/getInitialData", { apiUrl: v.url }))
      )
    );

    if (success.every((v) => v == true)) {
      commit("common/" + commonTypes.SET_LOADING_FINISH);
      dispatch("utility/jobs/getJobsData");

      // あとから個別ローディング
      // スケジュール設定
      if (state.isAuthorizedUnit.fair.view) {
        const success = await dispatch(
          "setInitialFairCountDaily",
          await dispatch("common/getInitialData", {
            apiUrl: "/fairs/schedules/count/daily",
          })
        );
        if (success) {
          commit(types.SET_LOADING_TYPE, "fair");
        }
      }
      // プラン
      if (state.isAuthorizedUnit.plan.view) {
        dispatch(
          "setInitialPlanList",
          await dispatch("common/getInitialData", {
            apiUrl: "/plans",
          })
        );
      }

      // common/notices がある場合はエラーモーダルを表示
      const notices = getters["common/notices"];
      if (Array.isArray(notices)) {
        dispatch("modal/showErrorModal", {
          text: notices.map((v) => v.displayText).join("\r\n"),
        });
      }
    } else {
      commit("common/" + commonTypes.ERROR);
      const errorModal = {
        text: state.modal.errorModal.text || MESSAGE_CONFIG.error,
      };
      dispatch("modal/showErrorModal", errorModal);
    }
  },

  /*
   * 初期データを state に反映
   ***********************************/
  // お知らせ & 稼働状況
  async setNewsList({ commit, dispatch, state }, { data }) {
    if (!data) return false;
    commit(types.SET_NEWS_DATA, { data: data.data });
    commit(types.SET_NEWS_MASTER, { master: data.master });
    return true;
  },
  // 連携状態
  async setLinkList({ commit }, { data }) {
    if (!data) return false;
    commit(types.SET_LINK_DATA, { cautions: data.caution });
    return true;
  },
  // 特典一覧 & 期限間近の個数
  async setInitialBenefitList({ commit, dispatch, state }, { data }) {
    if (!data) return false;
    commit(types.SET_BENEFIT_DATA, { benefit: data.data, master: data.master });
    return true;
  },
  // 特典数
  async setInitialBenefitCount({ commit, dispatch, state }, { data }) {
    if (!data) return false;
    commit(types.SET_BENEFIT_COUNT, {
      count: data.data,
      group_type: data.master.group_type,
    });
    return true;
  },
  // 式場特典一覧 & 期限間近の個数
  async setInitialHallBenefitList({ commit }, { data }) {
    if (!data) return false;
    commit(types.SET_HALL_BENEFIT_DATA, {
      benefit: data.data,
      master: data.master,
      datePeriod: data.hall_settings.hall_benefit_date_period,
    });
    return true;
  },
  // 画像一覧 & 期限間近の個数
  async setInitialImageList({ commit, dispatch, state }, { data }) {
    if (!data) return false;
    commit(types.SET_IMAGE_DATA, { image: data.data, master: data.master });
    return true;
  },
  // 画像数
  async setInitialImageCount({ commit }, { data }) {
    if (!data.data || !data.data.local) return false;
    commit(types.SET_IMAGE_COUNT, data.data.local);
    return true;
  },
  // 媒体関連
  async setInitialAccounts({ commit, dispatch, state }, { data }) {
    try {
      Object.keys(data.data).forEach((k) => {
        data.data[k].password = decrypt(data.data[k].encrypted_password, data.data[k].iv);
      });
    } catch (error) {
      commit("modal/setErrorModal", {
        text: "媒体の接続情報取得に失敗しました",
      });
    }
    if (!data) return false;
    commit(types.SET_ACCOUNT, {
      data: data.data,
      master: data.master,
      status: data.status,
    });
    commit(types.SET_ACCOUNT_LOG, {
      log: data.media_status,
      log_status: data.log_status,
    });
    commit(types.SET_ACCOUNT_LOGIN, { data: data.data });
    return true;
  },
  // 月ごとのフェア設定数
  async setInitialFairCountMonthly({ commit, dispatch, state }, { data }) {
    if (!data) return false;
    commit(types.SET_FAIR_COUNT_MONTHLY, data);
    return true;
  },
  // 日毎のフェア設定数
  async setInitialFairCountDaily({ commit, dispatch, state }, { data }) {
    if (!data) return false;
    commit(types.SET_CALENDAR, { calendar: data.calendar });
    commit(types.SET_MEDIA, { media: data.master.media });
    commit(types.SET_SCHEDULE, { data: data.data });
    return true;
  },
  // フェア一覧
  async setInitialFairList({ commit }, { data }) {
    if (!data) return false;
    commit(types.SET_FAIR_LIST, data.data);
    commit(types.SET_LOADING_TYPE, "fairList");
    return true;
  },
  // プラン一覧
  setInitialPlanList({ commit, dispatch, state }, { data }) {
    if (!data) return false;
    commit(types.UPDATE_PLAN_DATA, data.data);
    if (data.hall_settings) {
      const count = countPlan(data.data, data.hall_settings.plan_date_period);
      commit(types.UPDATE_PLAN_COUNT, count);
    }
    commit(types.SET_LOADING_TYPE, "plan");
    return true;
  },
  // 通知ログを既読にする前に確認モーダルを表示
  showConfirmNoticeModal({ dispatch }, param) {
    dispatch("modal/showConfirmModal", {
      text: "選択したログを確認済みに変更します。\r\n変更するとログは削除され、表示されなくなります。\r\n削除しますか？",
      status: 1,
      actionName: "confirmNotice",
      actionBtnText: "変更する",
      closeBtnText: "キャンセル",
      param,
    });
  },
  // 通知ログを既読にする
  async confirmNotice({ commit, dispatch, state }, payload) {
    commit("modal/setUpdateModal", { isShow: true });
    let { id, media_id, log_id } = payload;
    if (!id) id = log_id;
    let url = `/link-notices/${id}/confirm`;
    let response = await httpClient.post(url).catch((error) => {
      commit("common/" + commonTypes.ERROR);
      console.log(error);
    });
    let isRemovedLog = response && response.status == API_CODE.response.success;
    let isUpdatedLog = true;
    if (isRemovedLog) {
      commit(types.REMOVE_LOG, { id, media_id });
      commit(types.REMOVE_LINK_CAUTION, id);
      const isRemovedAll =
        state.account.log[media_id].logs.length === 0 &&
        state.account.log[media_id].notices.length === 0;
      if (isRemovedAll) dispatch("showLogModal", false);
    }
    const isConfirmedAll = state.account.log[media_id].notices.length === 0;
    if (isConfirmedAll) {
      let url = "/media/accounts";
      let response = await httpClient.get(url).catch((error) => {
        commit("common/" + commonTypes.ERROR);
        console.log(error);
      });
      isUpdatedLog = await dispatch("setInitialAccounts", response);
    }
    commit("modal/setUpdateModal", { isShow: false });
    if (isRemovedLog && isUpdatedLog) {
      commit("modal/setCompleteText", "削除しました");
      dispatch("modal/showCompleteModal");
    } else {
      dispatch("modal/showErrorModal", {
        text: "削除に失敗しました　時間をおいて再度お試しください",
      });
    }
  },
  // ログモーダルの表示切替
  showLogModal: ({ commit, dispatch, state }, isShow) => {
    commit(types.SHOW_LOG_MODAL, isShow);
  },
  // 各 unit の表示権限
  async setUnitPermission({ commit, state, getters }) {
    Object.keys(state.isAuthorizedUnit).forEach((unit) => {
      let value = {};
      Object.keys(state.isAuthorizedUnit[unit]).forEach((k) => {
        if (k == "permissionName") return;
        value[k] = getters["common/isAuthorized"]({
          name: state.isAuthorizedUnit[unit].permissionName,
          type: k,
        });
      });
      commit(types.SET_IS_AUTHORIZED_UNIT, { name: unit, value });
    });
  },
  async retry({ commit, dispatch }, jobId) {
    commit("modal/setUpdateModal", { isShow: true });
    const url = `/jobs/${jobId}/retry`;
    const response = await httpClient.post(url);
    const isSuccess = response && response.status == API_CODE.response.success;
    if (isSuccess) {
      commit("modal/setCompleteText", "更新しました");
      dispatch("modal/showCompleteModal");
    } else {
      dispatch("modal/showErrorModal", { text: MESSAGE_CONFIG.error });
    }
  },
};

export default actions;
