<template>
  <div>
    <block-title icon="sync_alt" title="コレモネっと" />
    <component :is="statusComponents" />
  </div>
</template>

<script>
import { serviceStatus } from "@/assets/hall-admin/coremonet-config";

import { mapGetters } from "vuex";
import BlockTitle from "@/components/molecules/top/BlockTitle.vue";
import CoremonetStatus from "@/components/organisms/top/CoremonetStatus.vue";
import CoremonetPromotion from "@/components/organisms/top/CoremonetPromotion.vue";

export default {
  components: {
    BlockTitle,
    CoremonetStatus,
    CoremonetPromotion,
  },
  computed: {
    ...mapGetters({
      statusCode: "common/coremonetStatus",
    }),
    statusComponents() {
      const promotionStatus = [
        serviceStatus.NOT_IN_SERVICE,
        serviceStatus.SUSPENDED,
      ];
      if (promotionStatus.includes(this.statusCode))
        return "coremonet-promotion";

      return "coremonet-status";
    },
  },
};
</script>
