<template>
  <modal-header-window
    class="modalNews"
    icon="info"
    :title="news.title"
    :is-set-footer="false"
    @close="close"
  >
    <template #content>
      <div class="news">
        <p class="date fontNum">{{ formatDate(news.publication_date) }}</p>
        <p class="text">{{ news.detail }}</p>
      </div>
    </template>
  </modal-header-window>
</template>

<script>
// lib
import formatDateText from "@/lib/formatDateText";
// component
import ModalHeaderWindow from "@/components/molecules/common/ModalHeaderWindow.vue";
export default {
  components: {
    ModalHeaderWindow,
  },
  props: {
    news: {
      type: Object,
      default: () => {},
    },
  },
  computed: {},
  methods: {
    close: function () {
      this.$emit("close");
    },
    formatDate: function (date) {
      return formatDateText(date);
    },
  },
};
</script>

<style scoped lang="scss">
.modalNews {
  ::v-deep .modalHeaderWindow {
    width: $GRID * (39 + 17 + 1 + 2); // ModalVoice, ModalContact と同じ幅
    .modalHeader {
      &_title {
        height: $GRID * 3;
        line-height: $GRID * 3;
        margin: 0 $GRID * 3 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .news {
    @include Box();
    border-radius: $BOX_RADIUS;
    padding: $GRID $GRID $GRID $GRID * 5.5;
    position: relative;
    word-break: break-all;
    .date {
      color: $COLOR_KEY;
      font-size: 15px;
      left: $GRID;
      position: absolute;
      top: $GRID;
    }
    .text {
      font-size: 13px;
      line-height: 1.8;
      margin-top: -2px;
      white-space: pre-line;
    }
  }
}
</style>
