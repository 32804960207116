<template>
  <div class="topBlockTitle">
    <i class="material-icons topTitleIcon">{{ icon }}</i>
    <h2 class="topTitle">{{ title }}</h2>
    <div :class="btnClass">
      <div>
        <slot name="btn" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    isSingle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    btnClass: function () {
      return this.isSingle ? "topBtn" : "topBtns";
    },
  },
};
</script>

<style lang="scss">
// TODO: 暫定対応: compat対応時にスタイル崩れが発生したため、scopedを一時的に削除
.top {
  &BlockTitle {
    align-items: center;
    display: flex;
    margin-bottom: $GRID;
  }
  &TitleIcon,
  &Title {
    color: $COLOR_KEY;
    font-size: 23px;
    font-weight: normal;
    line-height: 1;
  }
  &TitleIcon {
    font-size: 26px;
    margin-right: $GRID * 0.375;
  }
  &Btn,
  &Btns {
    margin-left: $GRID * 0.9;
  }
  &Btn {
    padding: $SUB_GRID 0;
    .btn {
      width: $GRID * 8 !important;
    }
  }
  &Btns {
    > div,
    .group {
      align-items: center;
      display: flex;
    }
    .group {
      border: 1px solid #c9c9c9;
      border-radius: $BOX_RADIUS;
      padding: $GRID * 0.5 $GRID;
      p {
        color: #727171;
        font-size: 12px;
        margin-right: $SUB_GRID;
      }
      & + .group {
        margin-left: $GRID;
      }
    }
    .btn + .btn {
      margin-left: $SUB_GRID * 2;
    }
  }
}
</style>
