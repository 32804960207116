<template>
  <div class="expiresSoonCount">
    <p class="count">
      計
      <span class="num fontNum">{{ count }}</span>
      {{ unit }}
    </p>
    <link-btn text="一覧で表示する" :href="href" />
  </div>
</template>

<script>
import LinkBtn from "@/components/atoms/common/LinkButton.vue";

export default {
  components: {
    LinkBtn,
  },
  props: {
    count: Number,
    unit: String,
    href: String,
  },
};
</script>

<style scoped lang="scss">
.expiresSoonCount {
  align-items: flex-end;
  background: $BG_COLOR_SUB;
  display: flex;
  justify-content: flex-end;
  padding: $GRID * 0.5;
  position: relative;
  width: 100%;
  .count {
    font-size: 9px;
    margin-right: $GRID;
    .num {
      display: inline-block;
      font-size: 22px;
      line-height: 1;
      margin-left: 4px;
    }
  }
}
</style>
