<template>
  <p class="d fontNum" :class="cssClass">{{ count }}</p>
</template>

<script>
export default {
  props: {
    day: Number,
    weekday: Number,
    count: {
      type: Number,
      default: 0,
    },
    isHoliday: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssClass: function() {
      return {
        isSaturday: this.weekday === 6,
        isSunday: this.weekday === 0,
        isHoliday: this.isHoliday,
        isFirstday: this.day === 1,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.d {
  width: $SCHEDULE_CELL_WIDTH;
  height: 100%;
  border-left: 1px solid $SCHEDULE_BORDER_COLOR;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $COLOR_SUB_TEXT;
  font-size: 12px;
  font-weight: bold;
  &.isSaturday {
    background: $COLOR_SATURDAY_LIGHT;
  }
  &.isSunday,
  &.isHoliday {
    background: $COLOR_SUNDAY_LIGHT;
  }
  &.isFirstday {
    border-left: none;
  }
}
</style>
