<template>
  <div class="infoUnit">
    <block-title icon="info" title="お知らせ" />
    <div class="infoUnitInr">
      <div class="newsBlock">
        <div class="newsBlockInr" v-if="news.list.length">
          <div class="news" v-for="n in news.list" :key="n.id">
            <p class="date fontNum">{{ formatDate(n.publication_date) }}</p>
            <p class="title">
              <span
                v-if="n.news_type"
                class="tag"
                :class="tagClass(n.news_type)"
              >{{ news.master.news_type[n.news_type] }}</span>
              {{ n.title }}
            </p>
            <p class="text">{{ n.description }}</p>
            <action-button
              v-if="!!n.detail"
              class="detailButton"
              text="詳細を見る"
              :is-select="true"
              @clickHandler="openModal(n)"
            />
          </div>
        </div>
        <p v-else class="newsEmpty">
          <span class="newsEmptyInr">現在、新しいお知らせはありません</span>
        </p>
      </div>
      <dl class="statusBlock" :class="{ isError }">
        <dt class="statusBlock_title">稼働状況</dt>
        <dd class="statusBlockInr">
          <img src="/img/bird_sync.gif" class="statusBlock_bird" />
          <p class="statusBlock_text">
            全媒体への連携が
            <span class="status">正常に稼働しています</span>
          </p>
        </dd>
      </dl>
    </div>
    <modal-news v-if="isShowModal" :news="selectedNews" @close="closeModal" />
  </div>
</template>

<script>
import ActionButton from "@/components/atoms/common/ActionButton.vue";
import BlockTitle from "@/components/molecules/top/BlockTitle.vue";
import IconSync from "@/components/atoms/common/IconSync.vue";
import ModalNews from "@/components/molecules/top/ModalNews.vue";
// Store
import { mapState } from "vuex";

export default {
  components: {
    ActionButton,
    BlockTitle,
    IconSync,
    ModalNews,
  },
  props: {
    news: Object,
  },
  data: function() {
    return {
      isShowModal: false,
      selectedNews: null,
    };
  },
  methods: {
    tagClass: function(type) {
      return {
        warning: type == 1,
        announce: type == 2,
      };
    },
    formatDate: function(date) {
      let d = [];
      d = date.split("-");
      return `${d[0].slice(-2)}/${parseInt(d[1], 10)}/${parseInt(d[2], 10)}`;
    },
    openModal: function(news) {
      this.isShowModal = true;
      this.selectedNews = news;
    },
    closeModal: function() {
      this.isShowModal = false;
      this.selectedNews = null;
    },
  },
};
</script>

<style scoped lang="scss">
.infoUnitInr {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
.newsBlock,
.statusBlock {
  @include Box();
  box-sizing: border-box;
  height: $GRID * 11;
  padding: $SUB_GRID * 2;
  width: $GRID * 41;
}
.newsBlock {
  @include DentedBox();
  overflow: auto;
}
.news {
  @include Box();
  border-radius: $BOX_RADIUS;
  padding: $GRID $GRID $GRID $GRID * 5.5;
  position: relative;
  word-break: break-all;
  .date {
    color: $COLOR_KEY;
    font-size: 15px;
    left: $GRID;
    position: absolute;
    top: $GRID;
  }
  .title {
    font-size: 13px;
  }
  .text {
    font-size: 11px;
    line-height: 1.8;
    margin-top: $SUB_GRID * 2;
    white-space: pre-line;
  }
  .tag {
    border: 1px solid;
    font-size: 11px;
    line-height: $GRID;
    margin-right: $SUB_GRID * 2;
    padding: 0 $GRID * 0.5;
    &.warning {
      border-color: $COLOR_WARNING;
    }
    &.announce {
      border-color: $COLOR_SUBMIT;
    }
  }
  .detailButton {
    border-color: $COLOR_SUB_LIGHT_TEXT;
    color: $COLOR_SUB_LIGHT_TEXT;
    font-size: 11px;
    margin-top: $GRID;
  }
  & + & {
    margin-top: $GRID * 0.5;
  }
}
.newsEmpty {
  align-items: center;
  color: $COLOR_KEY;
  display: flex;
  font-size: 14px;
  height: 100%;
  justify-content: center;
}
.statusBlock {
  @include gradientMain();
  position: relative;
  text-align: center;
  width: $GRID * 16;
  &.isError {
    background: $COLOR_ALERT;
  }
  &Inr {
    background: rgba(255, 255, 255, 0.2);
    border-radius: $BOX_RADIUS;
    box-shadow: $DEPTH_LV_0;
    box-sizing: border-box;
    height: 100%;
    padding: $GRID * 2.5 0 0;
    .isError & {
      background: rgba(255, 255, 255, 0.5);
    }
  }
  &_title {
    color: $COLOR_TEXT;
    font-size: 14px;
    font-weight: bold;
    left: 0;
    position: absolute;
    top: $GRID;
    width: 100%;
    .isError & {
      color: $COLOR_TEXT;
    }
  }
  &_icon {
    color: $COLOR_SUBMIT;
    font-size: 45px;
    .isError & {
      color: $COLOR_ALERT;
    }
  }
  &_bird {
    width: 60px;
  }
  &_text {
    font-size: 11px;
    line-height: 1.8;
    margin-top: $SUB_GRID;
    .status {
      display: block;
      font-size: 13px;
    }
  }
}
</style>
