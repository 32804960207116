<template>
  <div class="benefitDetail">
    <a class="benefitDetailLink" :href="`/benefit/?edit#b-${data.id}`">
      <div class="benefitDetailDate">
        <p class="period" :class="periodClass">適用期間 {{ master.license_status[data.license_status] }}</p>
        <p class="date fontNum">{{ valid_until }}</p>
      </div>

      <dl class="benefitDetailCount">
        <dt>適用フェア：</dt>
        <dd class="fontNum">{{ data.used_count }}</dd>
      </dl>
      <p class="benefitDetailTit">{{ data.title }}</p>
    </a>
    <inoperable-cover v-if="inSync" :is-small="true" />
  </div>
</template>

<script>
import formatDateText from "@/lib/formatDateText";
import InoperableCover from "@/components/atoms/common/InoperableCover.vue";

export default {
  components: {
    InoperableCover,
  },
  props: {
    data: Object,
    master: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    inSync: function() {
      const s = Object.values(this.data.link_statuses).some(
        (status) => status == 1
      );
      return s;
    },
    valid_until: function() {
      return formatDateText(this.data.valid_until);
    },
    periodClass: function() {
      let c = "";
      if (this.data.license_status == 2) {
        c = "isEnd";
      } else if (this.data.license_status == 1) {
        c = "isSoon";
      }
      return c;
    },
  },
};
</script>

<style scoped lang="scss">
.benefitDetail {
  background: $BG_COLOR_BOX;
  border: 1px solid #ececec;
  border-radius: $BOX_RADIUS;
  overflow: hidden;
  position: relative;
  &Link {
    color: $COLOR_TEXT;
    display: block;
    padding: 10px 7px 16px;
    text-decoration: none;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
    }
  }
  &Date {
    align-items: center;
    display: flex;
    .period {
      border: 1px solid #000;
      border-radius: $BOX_RADIUS * 0.5;
      display: inline-block;
      font-size: 10px;
      font-weight: bold;
      margin-right: 10px;
      padding: 1px 6px 0;
      vertical-align: top;
      &.isEnd {
        border-color: $COLOR_ALERT;
        color: $COLOR_ALERT;
      }
      &.isSoon {
        border-color: $COLOR_WARNING;
        color: $COLOR_WARNING;
      }
    }
    .date {
      font-size: 13px;
    }
  }
  &Count {
    position: absolute;
    right: 7px;
    top: 7px;
    dt,
    dd {
      display: inline;
    }
    dt {
      font-size: 10px;
    }
    dd {
      color: $COLOR_KEY;
      font-size: 14px;
    }
  }
  &Tit {
    font-size: 12px;
    margin-top: 13px;
  }
  & + .benefitDetail {
    margin-top: $GRID * 0.5;
  }
}
</style>
