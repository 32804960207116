<template>
  <dl class="blockCount">
    <dt class="blockCountText">{{ text }}</dt>
    <dd class="blockCountNum fontNum">{{ count }}</dd>
  </dl>
</template>

<script>
export default {
  props: {
    text: String,
    count: Number,
  },
};
</script>

<style scoped lang="scss">
.blockCount {
  align-items: baseline;
  display: flex;
  font-size: 11px;
  line-height: 1;
  justify-content: space-between;
  &Text {
    font-size: 100%;
  }
  &Num {
    color: $COLOR_KEY;
    font-size: 236%;
  }
}
</style>
